import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { ISideBarItem } from "@/interfaces/ISideBarItem";
import SideBarItem from "./sideBarItem";
import VenueContextService from "@/services/venueContextService";
import VenueProvider from "@/providers/venueProvider";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import translations from "@/translations/mapper";
import links from "@/utils/links";
import PageHeaderManager from "@/models/pageHeaderManager";
import CoreSpace from "models/coreSpace";

import OverviewIcon from "@/images/Overview_blue.svg";
import MapIcon from "@/images/Map_blue.svg";
import CleaningIcon from "@/images/BEClean_Icon.svg";
import IndoorClimateIcon from "@/images/BEVital_Icon.svg";
import EnergyManagementIcon from "@/images/Energy_blue.svg";
import RealEstateUtilizationIcon from "@/images/BEEquipped_Icon.svg";
import DocumentsIcon from "@/images/documents_blue.svg";
import NotificationsIcon from "@/images/notifications_blue.svg";
import SettingsIcon from "@/images/settings_blue.svg";
import "./sideBar.scss";

const SideBar: React.FC<{}> = () => {

    const [subscriptionValidationService, setSubscriptionValidationService] = useState<SubscriptionValidationService>();
    const [hasSettingsWriteAccess, setHasSettingsWriteAccess] = useState(false);
    const [sideBarItems, setSideBarItems] = useState<ISideBarItem[]>();
    const location = useLocation();

    const getSidebarItems = useCallback(() => {
        const activeVenue = VenueProvider.getActiveVenue();

        if (!subscriptionValidationService || !activeVenue) {
            return;
        }

        const hasIndoorClimateManagement = subscriptionValidationService!.venueHasAnyApplicableService(activeVenue, ["BEVital"]);
        const hasEnergyManagement = subscriptionValidationService!.venueHasAnyApplicableService(activeVenue, ["BEEnergized"]);
        const hasRealEstateUtilization = subscriptionValidationService!.venueHasAnyApplicableService(activeVenue, ["BEEquipped"]);
        const hasCleaning = subscriptionValidationService!.venueHasAnyApplicableService(activeVenue, ["BEClean"]);
        const hasEnergyManagementGasElectricity = subscriptionValidationService!.venueHasAnyApplicableSubscription(activeVenue, ["Gas", "Electricity"]);
        const hasMotion = subscriptionValidationService!.venueHasAnyApplicableSubscription(activeVenue, ["Motion"]);

        const prefixes = [
            links.map,
            links.documents,
            links.notifications._prefix,
            links.settings._prefix,
            links.indoorclimate._prefix,
            links.realestateutilization._prefix,
            links.energymanagement._prefix,
            links.cleaning._prefix,
        ];

        let items: ISideBarItem[] = [
            {
                linkTo: links.home,
                className: isPageActive(links.home, prefixes) ? "active" : "",
                dataTestAttr: "sidebar-overview-nav",
                imageSrc: OverviewIcon,
                translateId: translations.navigation.home,
                active: true,
                show: true,
            },
            {
                linkTo: links.map,
                className: isPageActive(links.map, []) ? "active" : "",
                dataTestAttr: "sidebar-map-nav",
                imageSrc: MapIcon,
                translateId: translations.navigation.map,
                active: true,
                show: true,
                showBottomDivider: true,
            },
        ];

        const serviceItems: ISideBarItem[] = [
            {
                linkTo: links.cleaning.pollution,
                className: isPageActive(links.cleaning._prefix, [links.settings._prefix]) ? "active" : "",
                dataTestAttr: "sidebar-cleaning-nav",
                imageSrc: CleaningIcon,
                translateId: translations.navigation.cleaning,
                active: hasCleaning,
                show: false,
                subItems: [
                    {
                        linkTo: links.cleaning.pollution,
                        className: isPageActive(links.cleaning.pollution) ? "active" : "",
                        translateId: translations.navigation.pollutionautodesk,
                        dataTestAttr: "sidebar-cleaning-pollution",
                        active: hasCleaning && hasRealEstateUtilization
                    }
                ],
            },
            {
                linkTo: links.energymanagement.portfolio,
                className: isPageActive(links.energymanagement._prefix, [links.settings._prefix]) ? "active" : "",
                dataTestAttr: "sidebar-energymanagement-nav",
                imageSrc: EnergyManagementIcon,
                translateId: translations.navigation.energy,
                active: hasEnergyManagement,
                show: true,
                subItems: [
                    {
                        linkTo: links.energymanagement.portfolio,
                        className: isPageActive(links.energymanagement.portfolio) ? "active" : "",
                        translateId: translations.navigation.portfolio,
                        dataTestAttr: "sidebar-energymanagement-portfolio",
                        active: true,
                    },
                    {
                        linkTo: links.energymanagement.overview,
                        className: isPageActive(links.energymanagement.overview) ? "active" : "",
                        translateId: translations.navigation.overview,
                        dataTestAttr: "sidebar-energymanagement-overview",
                        active: true,
                    },
                    {
                        linkTo: links.energymanagement.reports.baseline,
                        className: isPageActive(links.energymanagement.reports._prefix) ? "active" : "",
                        translateId: translations.navigation.consumption,
                        dataTestAttr: "sidebar-energymanagement-consumption",
                        active: true,
                    },
                    {
                        linkTo: links.energymanagement.savings,
                        className: isPageActive(links.energymanagement.savings) ? "active" : "",
                        translateId: translations.navigation.savings,
                        dataTestAttr: "sidebar-energymanagement-savings",
                        active: hasEnergyManagementGasElectricity,
                    },
                ],
            },
            {
                linkTo: links.realestateutilization.motion,
                className: isPageActive(links.realestateutilization._prefix, [links.settings._prefix, links.notifications._prefix]) ? "active" : "",
                dataTestAttr: "sidebar-realestateutilization-nav",
                imageSrc: RealEstateUtilizationIcon,
                translateId: translations.navigation.insight,
                active: hasRealEstateUtilization,
                show: true,
                subItems: getRealEstateUtilizationSubItems(activeVenue),
            },
            {
                linkTo: links.indoorclimate.general,
                className: isPageActive(links.indoorclimate._prefix, [links.settings._prefix, links.notifications._prefix]) ? "active" : "",
                dataTestAttr: "sidebar-indoorclimate-nav",
                imageSrc: IndoorClimateIcon,
                translateId: translations.navigation.indoorclimate,
                active: hasIndoorClimateManagement,
                show: true,
                subItems: [
                    {
                        linkTo: links.indoorclimate.general,
                        className: isPageActive(links.indoorclimate.general) ? "active" : "",
                        translateId: translations.navigation.comfortinsights,
                        dataTestAttr: "sidebar-indoorclimate-comfort",
                        active: true,
                    },
                ],
            },
        ];

        serviceItems.sort((a, b) => {
            if (a.active > b.active) {
                return -1;
            }
            if (a.active < b.active) {
                return 1;
            }
            return 0;
        });

        serviceItems[serviceItems.length - 1].showBottomDivider = true;

        items = items.concat(serviceItems);

        items = items.concat([
            {
                linkTo: links.documents,
                className: isPageActive(links.documents) ? "active" : "",
                dataTestAttr: "sidebar-documents-nav",
                imageSrc: DocumentsIcon,
                translateId: "navigation.documents",
                active: true,
                show: true,
            },
            {
                linkTo: links.notifications.realestateutilization,
                className: isPageActive(links.notifications._prefix, [links.settings.indoorclimate.notifications]) ? "active" : "",
                dataTestAttr: "sidebar-notifications-nav",
                imageSrc: NotificationsIcon,
                translateId: translations.navigation.notifications,
                active: true,
                show: hasRealEstateUtilization || hasIndoorClimateManagement,
                subItems: [
                    {
                        linkTo: links.notifications.realestateutilization,
                        className: isPageActive(links.notifications.realestateutilization) ? "active" : "",
                        translateId: translations.navigation.realestateutilization,
                        dataTestAttr: "sidebar-notifications-realestateutilization",
                        active: hasRealEstateUtilization && hasMotion,
                    },
                    {
                        linkTo: links.notifications.indoorclimate,
                        className: isPageActive(links.notifications.indoorclimate) ? "active" : "",
                        translateId: translations.navigation.indoorclimate,
                        dataTestAttr: "sidebar-notifications-indoorclimate",
                        active: hasIndoorClimateManagement,
                    },
                ],
            },
            {
                linkTo: hasRealEstateUtilization ? links.settings.realestateutilization.availability : links.settings.indoorclimate.notifications,
                className: isPageActive(links.settings._prefix) ? "active" : "",
                dataTestAttr: "sidebar-settings-nav",
                imageSrc: SettingsIcon,
                translateId: translations.navigation.settings,
                active: true,
                show: hasSettingsWriteAccess && (hasIndoorClimateManagement || hasRealEstateUtilization),
                subItems: [
                    {
                        linkTo: determineCorrectRealEstateUtilizationSettingsLink("realestateutilization-settings"),
                        className: isPageActive(links.settings.realestateutilization._prefix) ? "active" : "",
                        translateId: translations.navigation.realestateutilization,
                        dataTestAttr: "sidebar-settings-realestateutilization",
                        active: hasRealEstateUtilization,
                    },
                    {
                        linkTo: links.settings.indoorclimate.notifications,
                        className: isPageActive(links.settings.indoorclimate._prefix) ? "active" : "",
                        translateId: translations.navigation.indoorclimate,
                        dataTestAttr: "sidebar-settings-indoorclimate",
                        active: hasIndoorClimateManagement,
                    },
                ],
            }]
        );
        setSideBarItems(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionValidationService, hasSettingsWriteAccess, location]);

    useEffect(() => {
        const initializeSidebar = async (): Promise<void> => {
            const service = await SubscriptionValidationService.GetInstanceAsync();
            const hasWriteAccess = await new VenueContextService().hasWritePermission();

            setSubscriptionValidationService(service);
            setHasSettingsWriteAccess(hasWriteAccess);

            AppEventHub.on(AppEvents.BuildingSelected, getSidebarItems);
        };
        initializeSidebar();

        return (): void => AppEventHub.off(AppEvents.BuildingSelected, getSidebarItems);
    }, [getSidebarItems]);

    useEffect(() => {
        if (subscriptionValidationService) {
            getSidebarItems();
        }
    }, [subscriptionValidationService, getSidebarItems, hasSettingsWriteAccess]);

    function isPageActive(url: string, urlsToExclude?: string[], matchExact?: boolean): boolean {
        const path = window.location.pathname.trim().toLowerCase();

        if (matchExact) {
            return path === url;
        } else {
            const isActive = path.indexOf(url) !== -1;
            const containsNoExcludedUrl = !urlsToExclude?.find((u) => path.indexOf(u) !== -1);

            return isActive && containsNoExcludedUrl;
        }
    }

    function getRealEstateUtilizationSubItems(activeVenue: CoreSpace): any[] {
        const hasMotionSanitary = subscriptionValidationService!.venueHasAnyApplicableSubscription(activeVenue, ["MotionSanitary"]);
        const hasMotion = subscriptionValidationService!.venueHasAnyApplicableSubscription(activeVenue, ["Motion"]);
        const hasNoShow = subscriptionValidationService!.venueHasAnyApplicableSubscription(activeVenue, ["NoShowRooms"]);

        return [
            {
                linkTo: links.realestateutilization.motion,
                className: isPageActive(links.realestateutilization.motion) ? "active" : "",
                translateId: translations.navigation.motioninsights,
                dataTestAttr: "sidebar-realestateutilization-motion",
                active: hasMotion
            },
            {
                linkTo: links.realestateutilization.heatmap,
                className: isPageActive(links.realestateutilization.heatmap) ? "active" : "",
                translateId: translations.navigation.heatmap,
                dataTestAttr: "sidebar-realestateutilization-heatmap",
                active: hasMotion
            },
            {
                linkTo: links.realestateutilization.sanitary,
                className: isPageActive(links.realestateutilization.sanitary) ? "active" : "",
                translateId: translations.navigation.sanitaryinsights,
                dataTestAttr: "sidebar-realestateutilization-sanitary",
                active: hasMotionSanitary
            },
            {
                linkTo: links.realestateutilization.noshow,
                className: isPageActive(links.realestateutilization.noshow, [links.settings._prefix]) ? "active" : "",
                translateId: translations.navigation.noshowinsights,
                dataTestAttr: "sidebar-realestateutilization-noshow",
                active: hasNoShow
            },
        ];
    }

    function determineCorrectRealEstateUtilizationSettingsLink(pageName: string): string {
        const activeVenue = VenueProvider.getActiveVenue();
        if (subscriptionValidationService === undefined || activeVenue === undefined) {
            // Subscription validation service or venue hasn't loaded in yet, default to fallback for now.
            // This will update after componentDidMount goes off.
            return links.settings.realestateutilization.availability;
        }

        const settingTabs = PageHeaderManager.getHeadersForPage(pageName);

        if (settingTabs === null) {
            throw new Error("Error getting tabs for settings menu!");
        }

        for (const tab of settingTabs.tabs) {
            if (tab.onlyShowForVenuesWithSubscriptionTypes === undefined || subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, tab.onlyShowForVenuesWithSubscriptionTypes)) {
                return tab.relativeUrl;
            }
        }

        // This fallback should only happen when the entire settings menu isn't visible anyway.
        return links.settings.realestateutilization.availability;
    }

    return (
        <div id="sidebar-dropdown">
            {sideBarItems && sideBarItems.map((item) => {
                return <SideBarItem key={item.linkTo} sideBarItem={item} ></SideBarItem>;
            })}
        </div>
    );
}

export default SideBar;