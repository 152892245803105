import * as React from "react";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import HomePage from "./home/homePage";
import MapPage from "./map/tabs/mapPage";
import CleaningPage from "./cleaning/cleaningPage";

import IAppProps from "./IAppProps";
import IAppState from "./IAppState";
import SideBar from "../components/sidebar/sideBar";
import UserSettingsMenu from "../components/header/userSettingsMenu";
import VenuePicker from "../components/header/venuePicker";
import SwitchAppMenu from "../components/header/switchAppMenu";

import "./app.scss";
import { BeyondEyesLogoWhite } from "@/images";

import PageHeaderManager from "../models/pageHeaderManager";
import MotionInsightsPage from "./realestateutilization/tabs/motionInsightsPage";
import EnergyPortfolioPage from "./energymanagement/tabs/energyPortfolioPage";
import ComfortNotificationsPage from "./notifications/tabs/comfortNotificationsPage";
import SanitaryInsightsPage from "./realestateutilization/tabs/sanitaryInsightsPage";
import NoShowInsightsPage from "./realestateutilization/tabs/noShowInsightsPage";
import WorkspaceNotificationsPage from "./notifications/tabs/workspaceNotificationsPage";
import ComfortThresholdsSettingsPage from "./settings/indoorclimate/subtabs/comfortThresholdsSettingsPage";
import DocumentsPage from "./documents/documentsPage";
import EnergyTemperaturePage from "./energymanagement/tabs/reports/energyTemperaturePage";

import VenueProvider from "../providers/venueProvider";
import AdminMessageBar from "../components/adminMessageBar/adminMessageBar";
import Page from "./page";
import EnergyOverviewPage from "./energymanagement/tabs/energyOverviewPage";
import HolidayConsumptionPage from "./energymanagement/tabs/reports/holidayConsumptionPage";
import EnergyBaselinePage from "./energymanagement/tabs/reports/energyBaselinePage";
import EnergyWeekOverviewPage from "./energymanagement/tabs/reports/energyWeekOverviewPage";
import EnergySavingsPage from "./energymanagement/tabs/energySavingsPage";
import CoreSpaceService from "../services/coreSpaceService";
import { translations } from "../translations/mapper";
import links from "../utils/links";
import IPageHeaderItem from "../interfaces/IPageHeaderItem";
import heatmapPage from "./realestateutilization/tabs/heatmapPage";
import IndoorClimatePage from "./indoorclimate/indoorClimatelPage";
import workSpaceSettingsAvailabilityTab from "./settings/realestateutilization/subtabs/workspacesavailability/workSpaceSettingsAvailabilityTab";
import workSpaceReservationSettings from "./settings/realestateutilization/subtabs/workspacesgeneral/workSpaceReservationSettings";
import paperbinsSettingsTab from "./settings/realestateutilization/subtabs/sanitary/paperbinsSettingsTab";
import toiletSettingsTab from "./settings/realestateutilization/subtabs/sanitary/toiletSettingsTab";
import realEstateUtilizationRoomsSettingsPage from "./settings/realestateutilization/subtabs/rooms/realEstateUtilizationRoomsSettingsPage";
import workSpaceSettingsTypeTab from "./settings/realestateutilization/subtabs/workspacestags/workSpaceSettingsTypeTab";

class App extends React.Component<IAppProps, IAppState> {

    public constructor(props: IAppProps) {
        super(props);
        this.initializeAppPageHeaders();

        const state: IAppState = {};

        this.state = state;
    }

    public async componentDidMount(): Promise<void> {
        if (!VenueProvider.getActiveVenue()) {
            const coreSpaceService = new CoreSpaceService();
            const venues = await coreSpaceService.getVenues();
            const venue = venues[0];

            if (venue) {
                VenueProvider.saveActiveVenue(venue);
            }
        }
    }

    public render(): JSX.Element {
        return this.renderFullApp();
    }

    private initializeAppPageHeaders(): void {
        const beRealEstateUtilizationTabs: IPageHeaderItem[] = [
            {
                id: "realestateutilization-availability",
                relativeUrl: links.settings.realestateutilization.availability,
                translationLabel: translations.pages.settings.realestateutilization.availability,
                onlyShowForVenuesWithSubscriptionTypes: ["Motion", "ReserveWorkspaces"]
            },
            {
                id: "realestateutilization-tags",
                relativeUrl: links.settings.realestateutilization.tags,
                translationLabel: translations.pages.settings.realestateutilization.tags,
                onlyShowForVenuesWithSubscriptionTypes: ["FindWorkspaces", "ReserveWorkspaces"]
            },
            {
                id: "realestateutilization-workspacereservations",
                relativeUrl: links.settings.realestateutilization.workspacereservations,
                translationLabel: translations.pages.settings.realestateutilization.workspacereservations,
                onlyShowForVenuesWithSubscriptionTypes: ["AutomaticWorkspaceCancellation", "NoShowWorkspaces", "BEFMISWorkspaces"]
            },
            {
                id: "realestateutilization-roomreservations",
                relativeUrl: links.settings.realestateutilization.roomreservations,
                translationLabel: translations.pages.settings.realestateutilization.roomreservations,
                onlyShowForVenuesWithSubscriptionTypes: ["AutomaticRoomCancellation", "NoShowRooms", "BEFMISRooms"]
            },
            {
                id: "realestateutilization-paperbins",
                relativeUrl: links.settings.realestateutilization.paperbins,
                translationLabel: translations.pages.settings.realestateutilization.paperbins,
                onlyShowForVenuesWithSubscriptionTypes: ["PaperbinIncidents"]
            },
            {
                id: "realestateutilization-toilets",
                relativeUrl: links.settings.realestateutilization.toilets,
                translationLabel: translations.pages.settings.realestateutilization.toilets,
                onlyShowForVenuesWithSubscriptionTypes: ["ToiletIncidents"]
            }
        ];

        PageHeaderManager.add({
            pageName: "realestateutilization-settings",
            tabs: beRealEstateUtilizationTabs
        });

        PageHeaderManager.add({
            pageName: "indoorclimate-settings",
            tabs: [
                {
                    id: "indoorclimate",
                    relativeUrl: links.settings.indoorclimate.notifications,
                    translationLabel: translations.navigation.notifications
                }
            ]
        });

        PageHeaderManager.add({
            pageName: "energymanagement-reports",
            tabs: [
                {
                    id: "energymanagement-usage",
                    relativeUrl: links.energymanagement.reports.baseline,
                    translationLabel: translations.navigation.baseline
                },
                {
                    id: "energymanagement-holidays",
                    relativeUrl: links.energymanagement.reports.holidays,
                    translationLabel: "navigation.holidays"
                },
                {
                    id: "energymanagement-week-overview",
                    relativeUrl: links.energymanagement.reports.weekoverview,
                    translationLabel: translations.navigation.energyweekoverview
                },
                {
                    id: "energymanagement-temperature",
                    relativeUrl: links.energymanagement.reports.temperature,
                    translationLabel: translations.navigation.energizedtemperature
                }
            ]
        });
    }

    private renderFullApp(): JSX.Element {
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-light pr-5 sticky" id="user-menu-navbar">
                    <Link to="/" ><img src={BeyondEyesLogoWhite} className="logo" /></Link>
                    <div className="navbar-nav ml-auto">
                        <div className="nav-item nav-link ml-5">
                            <VenuePicker />
                        </div>
                        <div className="nav-item nav-link ml-5">
                            <SwitchAppMenu />
                        </div>
                        <div className="nav-item nav-link ml-5 mr-5 pr-4">
                            <UserSettingsMenu />
                        </div>
                    </div>
                </nav>
                <div className="d-flex">
                    <Route component={SideBar} />
                    <main className="container-fluid">
                        <AdminMessageBar />
                        {routes()}
                    </main>
                </div>
            </>
        );
    }
}

const routes = (): JSX.Element => (
    <Switch>
        <Route exact path={links.home} render={(props): JSX.Element => (
            <Page {...props} component={HomePage} title="Home" />
        )} />
        <Route exact path={links.map} render={(props): JSX.Element => (
            <Page {...props} component={MapPage} title="Map" />
        )} />
        <Route exact path={links.cleaning.pollution} render={(props): JSX.Element => (
            <Page {...props} component={CleaningPage} title="Cleaning pollution Autodesk" />
        )} />
        <Route exact path={links.indoorclimate.general} render={(props): JSX.Element => (
            <Page {...props} component={IndoorClimatePage} title="Indoor climate" />
        )} />
        <Route exact path={links.realestateutilization.motion} render={(props): JSX.Element => (
            <Page {...props} component={MotionInsightsPage} title="Real estate utilization insights" />
        )} />
        <Route exact path={links.realestateutilization.heatmap} render={(props): JSX.Element => (
            <Page {...props} component={heatmapPage} title="Real estate utilization heatmap" />
        )} />
        <Route exact path={links.realestateutilization.sanitary} render={(props): JSX.Element => (
            <Page {...props} component={SanitaryInsightsPage} title="Sanitary insights" />
        )} />
        <Route exact path={links.realestateutilization.noshow} render={(props): JSX.Element => (
            <Page {...props} component={NoShowInsightsPage} title="No-show insights" />
        )} />
        <Route exact path={links.energymanagement.portfolio} render={(props): JSX.Element => (
            <Page {...props} component={EnergyPortfolioPage} title="Energy Management portfolio" />
        )} />
        <Route exact path={links.energymanagement.overview} render={(props): JSX.Element => (
            <Page {...props} component={EnergyOverviewPage} title="Energy Management overview" />
        )} />
        <Route exact path={links.energymanagement.reports.baseline} render={(props): JSX.Element => (
            <Page {...props} component={EnergyBaselinePage} title="Energy Management baseline" />
        )} />
        <Route exact path={links.energymanagement.reports.holidays} render={(props): JSX.Element => (
            <Page {...props} component={HolidayConsumptionPage} title="Energy Management holidays" />
        )} />
        <Route exact path={links.energymanagement.reports.weekoverview} render={(props): JSX.Element => (
            <Page {...props} component={EnergyWeekOverviewPage} title="Energy Management week overview" />
        )} />
        <Route exact path={links.energymanagement.reports.temperature} render={(props): JSX.Element => (
            <Page {...props} component={EnergyTemperaturePage} title="Energy Management temperature" />
        )} />
        <Route exact path={links.energymanagement.savings} render={(props): JSX.Element => (
            <Page {...props} component={EnergySavingsPage} title="Energy Management savings" />
        )} />
        <Route exact path={links.documents} render={(props): JSX.Element => (
            <Page {...props} component={DocumentsPage} title="Documents" />
        )} />
        <Route exact path={links.notifications.indoorclimate} render={(props): JSX.Element => (
            <Page {...props} component={ComfortNotificationsPage} title="Indoor climate notifications" />
        )} />
        <Route exact path={links.notifications.realestateutilization} render={(props): JSX.Element => (
            <Page {...props} component={WorkspaceNotificationsPage} title="Real estate utilization notifications" />
        )} />
        <Route exact path={links.settings.realestateutilization.availability} render={(props): JSX.Element => (
            <Page {...props} component={workSpaceSettingsAvailabilityTab} title="Real estate utilization availability settings" />
        )} />
        <Route exact path={links.settings.realestateutilization.workspacereservations} render={(props): JSX.Element => (
            <Page {...props} component={workSpaceReservationSettings} title="Real estate utilization reservation settings" />
        )} />
        <Route exact path={links.settings.realestateutilization.tags} render={(props): JSX.Element => (
            <Page {...props} component={workSpaceSettingsTypeTab} title="Real estate utilization workspace tag settings" />
        )} />
        <Route exact path={links.settings.realestateutilization.roomreservations} render={(props): JSX.Element => (
            <Page {...props} component={realEstateUtilizationRoomsSettingsPage} title="Real estate utilization no-show settings" />
        )} />
        <Route exact path={links.settings.realestateutilization.paperbins} render={(props): JSX.Element => (
            <Page {...props} component={paperbinsSettingsTab} title="Real estate utilization paperbin settings" />
        )} />
        <Route exact path={links.settings.realestateutilization.toilets} render={(props): JSX.Element => (
            <Page {...props} component={toiletSettingsTab} title="Real estate utilization toilet settings" />
        )} />
        <Route exact path={links.settings.indoorclimate.notifications} render={(props): JSX.Element => (
            <Page {...props} component={ComfortThresholdsSettingsPage} title="Indoor climate notification settings" />
        )} />
        <Redirect exact from="/null" to="/" />
        <Route component={NoMatch} />
    </Switch>
);


const NoMatch = ({ location }): JSX.Element => (
    <div>
        <h3>
            No match for <code>{location.pathname}</code>
        </h3>
    </div>
);

export default withTranslation()(App);
