export const translations = {
    "tables": {
        "nodata": "tables.nodata",
        "rowsperpage": "tables.rowsperpage",
        "loadingtext": "tables.loadingtext"
    },
    "alerts": {
        "navigation": "alerts.navigation"
    },
    "buttons": {
        "save": "buttons.save",
        "logout": "buttons.logout",
        "dropdowns": {
            "asset": "buttons.dropdowns.asset",
            "comfortasset": "buttons.dropdowns.comfortasset",
            "nooptions": "buttons.dropdowns.nooptions",
        },
        "map": {
            "show": "buttons.map.show",
            "hide": "buttons.map.hide"
        },
        "venue": {
            "currentvenue": "buttons.venue.currentvenue",
            "filter": "buttons.venue.filter",
            "noresults": "buttons.venue.noresults",
            "clear": "buttons.venue.clear"
        }
    },
    "select": {
        "wholevenue": "select.wholevenue",
    },
    "dates": {
        "day": "dates.day",
        "week": "dates.week",
        "month": "dates.month",
        "year": "dates.year",
        "january": "dates.january",
        "february": "dates.february",
        "march": "dates.march",
        "april": "dates.april",
        "may": "dates.may",
        "june": "dates.june",
        "july": "dates.july",
        "august": "dates.august",
        "september": "dates.september",
        "october": "dates.october",
        "november": "dates.november",
        "december": "dates.december",
        "monday": "dates.monday",
        "tuesday": "dates.tuesday",
        "wednesday": "dates.wednesday",
        "thursday": "dates.thursday",
        "friday": "dates.friday",
        "saturday": "dates.saturday",
        "sunday": "dates.sunday",
        "shorthand": {
            "monday": "dates.shorthand.monday",
            "tuesday": "dates.shorthand.tuesday",
            "wednesday": "dates.shorthand.wednesday",
            "thursday": "dates.shorthand.thursday",
            "friday": "dates.shorthand.friday",
            "saturday": "dates.shorthand.saturday",
            "sunday": "dates.shorthand.sunday",
            "january": "dates.shorthand.january",
            "february": "dates.shorthand.february",
            "march": "dates.shorthand.march",
            "april": "dates.shorthand.april",
            "may": "dates.shorthand.may",
            "june": "dates.shorthand.june",
            "july": "dates.shorthand.july",
            "august": "dates.shorthand.august",
            "september": "dates.shorthand.september",
            "october": "dates.shorthand.october",
            "november": "dates.shorthand.november",
            "december": "dates.shorthand.december",
            "upto": "dates.shorthand.upto"
        },
        "holidays": {
            "title": "dates.holidays.title",
            "newyearsday": "dates.holidays.newyear'sday",
            "goodfriday": "dates.holidays.goodfriday",
            "eastersunday": "dates.holidays.eastersunday",
            "eastermonday": "dates.holidays.eastermonday",
            "kingsday": "dates.holidays.king'sday",
            "liberationday": "dates.holidays.liberationday",
            "ascensionday": "dates.holidays.ascensionday",
            "pentecost": "dates.holidays.pentecost",
            "whitmonday": "dates.holidays.whitmonday",
            "christmasday": "dates.holidays.christmasday",
            "ststephensday": "dates.holidays.st.stephen'sday"
        }
    },
    "navigation": {
        "home": "navigation.home",
        "map": "navigation.map",
        "cleaning": "navigation.cleaning",
        "indoorclimate": "navigation.indoorclimate",
        "realestateutilization": "navigation.realestateutilization",
        "energy": "navigation.energy",
        "notifications": "navigation.notifications",
        "insight": "navigation.insight",
        "motioninsights": "navigation.motioninsights",
        "comfortinsights": "navigation.comfortinsights",
        "sanitaryinsights": "navigation.sanitaryinsights",
        "noshowinsights": "navigation.noshowinsights",
        "heatmap": "navigation.heatmap",
        "documents": "navigation.documents",
        "savings": "navigation.savings",
        "consumption": "navigation.consumption",
        "holidays": "navigation.holidays",
        "baseline": "navigation.baseline",
        "overview": "navigation.overview",
        "settings": "navigation.settings",
        "noshow": "navigation.noshow",
        "portfolio": "navigation.portfolio",
        "pollution": "navigation.pollution",
        "pollutionautodesk": "navigation.pollutionautodesk",
        "energizedtemperature": "navigation.energizedtemperature",
        "energyweekoverview": "navigation.energyweekoverview"
    },
    "question": {
        "title": "question.title",
        "urgent": "question.urgent",
        "nextmeeting": "question.nextmeeting",
        "sendmessage": "question.sendmessage",
        "sendbutton": "question.sendbutton",
        "close": "question.close",
        "thanks": "question.thanks",
        "yourname": "question.yourname",
        "yourquestion": "question.yourquestion"
    },
    "noaccess": {
        "title": "noAccess.title",
        "subtitle": "noAccess.subtitle",
        "firstline": "noAccess.firstline",
        "firstoption": "noAccess.firstoption",
        "secondoption": "noAccess.secondoption",
        "lastline": "noAccess.lastline",
        "currentuser": "noAccess.currentuser",
        "errormessage": "noAccess.errormessage"
    },
    "datepicker": {
        "startdate": "datepicker.startdate",
        "enddate": "datepicker.enddate",
        "today": "datepicker.today"
    },
    "emailinput": {
        "placeholder": "emailinput.placeholder",
        "invalidemail": "emailinput.invalidemail",
        "alreadyadded": "emailinput.alreadyadded"
    },
    "pagination": {
        "showing": "pagination.showing",
        "to": "pagination.to",
        "of": "pagination.of",
        "items": "pagination.items",
        "itemsperpage": "pagination.itemsperpage"
    },
    "comfort": {
        "temperature": "comfort.temperature",
        "carbondioxide": "comfort.carbondioxide",
        "humidity": "comfort.humidity",
        "sound": "comfort.sound",
        "light": "comfort.light",
        "empty": "comfort.empty"
    },
    "energy": {
        "buttons": {
            "nomainmeteroptions": "energy.buttons.nomainmeteroptions"
        },
        "energyportfolio": {
            "title": "energy.energyportfolio.title",
            "yearlyconsumed": "energy.energyportfolio.yearlyconsumed",
            "monthlyconsumed": "energy.energyportfolio.monthlyconsumed"
        },
        "portfolio": {
            "title": "energy.portfolio.title",
            "columns": {
                "venue": "energy.portfolio.columns.venue",
                "subscription": "energy.portfolio.columns.subscription",
                "yearsavings": "energy.portfolio.columns.yearsavings",
                "goalsavings": "energy.portfolio.columns.goalsavings",
                "totalsavings": "energy.portfolio.columns.totalsavings"
            }
        },
        "managementoverview": {
            "title": "energy.managementoverview.title",
            "tiles": {
                "total": {
                    "title": "energy.managementoverview.tiles.total.title",
                    "costs": "energy.managementoverview.tiles.total.costs",
                    "electricity": "energy.managementoverview.tiles.total.electricity",
                    "gas": "energy.managementoverview.tiles.total.gas",
                    "heat": "energy.managementoverview.tiles.total.heat",
                },
                "thisyear": {
                    "title": "energy.managementoverview.tiles.thisyear.title",
                    "costs": "energy.managementoverview.tiles.thisyear.costs",
                    "electricity": "energy.managementoverview.tiles.thisyear.electricity",
                    "gas": "energy.managementoverview.tiles.thisyear.gas",
                    "heat": "energy.managementoverview.tiles.thisyear.heat",
                },
                "savings": {
                    "title": "energy.managementoverview.tiles.savings.title",
                    "solarpanels": "energy.managementoverview.tiles.savings.solarpanels",
                    "trees": "energy.managementoverview.tiles.savings.trees",
                    "co2": "energy.managementoverview.tiles.savings.co2",
                    "percentage": "energy.managementoverview.tiles.savings.percentage",
                }
            }
        },
        "progressbar": {
            "year": "energy.progressbar.year",
            "savingsunknown": "energy.progressbar.savingsunknown",
            "saved": "energy.progressbar.saved",
            "ontarget": "energy.progressbar.ontarget",
            "belowtarget": "energy.progressbar.belowtarget",
            "stillunknown": "energy.progressbar.stillunknown"
        },
        "topvenuesavings": {
            "title": "energy.topvenuesavings.title",
            "columns": {
                "venue": "energy.topvenuesavings.columns.venue",
                "currentsavingspercentage": "energy.topvenuesavings.columns.currentsavingspercentage",
                "currentsavingseuro": "energy.topvenuesavings.columns.currentsavingseuro",
                "currentsavingskwh": "energy.topvenuesavings.columns.currentsavingskwh",
                "currentsavingsm3": "energy.topvenuesavings.columns.currentsavingsm3",
                "currentsavingsgj": "energy.topvenuesavings.columns.currentsavingsgj",
                "goalsavings": "energy.topvenuesavings.columns.goalsavings",
            }
        },
        "general": {
            "nodataavailable": "energy.general.nodataavailable",
            "legendexplanation": "energy.general.legendexplanation"
        }
    },
    "pages": {
        "home": {
            "title": "pages.home.title",
            "all": "pages.home.all",
            "nosubscriptions": "pages.home.nosubscriptions",
            "total": {
                "title": "pages.home.total.title"
            },
            "comfort": {
                "comfortheader": "pages.home.comfort.comfortheader",
                "temperature": "pages.home.comfort.temperature",
                "humidity": "pages.home.comfort.humidity",
                "norecentdata": "pages.home.comfort.norecentdata",
                "more": "pages.home.comfort.more",
                "less": "pages.home.comfort.less",
                "nomoreresultstoshow": "pages.home.comfort.nomoreresultstoshow"
            },
            "occupancy": {
                "occupancyheader": "pages.home.occupancy.occupancyheader",
                "assetsavailable": "pages.home.occupancy.assetsavailable",
                "assetssoonavailable": "pages.home.occupancy.assetssoonavailable",
                "assetsoccupied": "pages.home.occupancy.assetsoccupied"
            },
            "actual": {
                "building": "pages.home.actual.building",
                "status": "pages.home.actual.status",
                "title": "pages.home.actual.title"
            },
            "latest": {
                "title": "pages.home.latest.title",
                "datetime": "pages.home.latest.datetime",
                "category": "pages.home.latest.category",
                "venue": "pages.home.latest.venue",
                "asset": "pages.home.latest.asset",
                "description": "pages.home.latest.description",
                "status": "pages.home.latest.status"
            },
            "graph": {
                "total": "pages.home.graph.total",
                "currentweek": "pages.home.graph.currentweek"
            }
        },
        "map": {
            "title": "pages.map.title",
            "noapikey": "pages.map.noapikey",
            "nomapwizevenue": "pages.map.nomapwizevenue",
            "norawdatafound": "pages.map.norawdatafound",
            "noassetsselected": "pages.map.noassetsselected",
            "assetinsights": {
                "columns": {
                    "datetime": "pages.map.assetinsights.columns.datetime",
                    "occupancy": "pages.map.assetinsights.columns.occupancy",
                    "peoplecount": "pages.map.assetinsights.columns.peoplecount",
                    "temperature": "pages.map.assetinsights.columns.temperature",
                    "humidity": "pages.map.assetinsights.columns.humidity",
                    "carbondioxide": "pages.map.assetinsights.columns.carbondioxide"
                },
                "values": {
                    "occupied": "pages.map.assetinsights.values.occupied",
                    "unoccupied": "pages.map.assetinsights.values.unoccupied"
                }
            }
        },
        "pollutionmap": {
            "legendtitle": "pages.pollutionmap.legendtitle",
            "popup": {
                "lastcleaned": "pages.pollutionmap.popup.lastcleaned",
                "timeoccupied": "pages.pollutionmap.popup.timeoccupied",
                "currentlyfree": "pages.pollutionmap.popup.currentlyfree",
                "status": "pages.pollutionmap.popup.status",
                "values": {
                    "used": "pages.pollutionmap.popup.values.used",
                    "clean": "pages.pollutionmap.popup.values.clean",
                    "days": "pages.pollutionmap.popup.values.days",
                    "day": "pages.pollutionmap.popup.values.day",
                    "hour": "pages.pollutionmap.popup.values.hour",
                    "hours": "pages.pollutionmap.popup.values.hours",
                    "minutes": "pages.pollutionmap.popup.values.minutes",
                    "minute": "pages.pollutionmap.popup.values.minute",
                    "yes": "pages.pollutionmap.popup.values.yes",
                    "no": "pages.pollutionmap.popup.values.no"
                }
            }
        },
        "cleaning": {
            "title": "pages.cleaning.title",
            "titleshort": "pages.cleaning.titleshort",
            "teaser": {
                "subtitle": "pages.cleaning.teaser.subtitle",
                "paragraph1": "pages.cleaning.teaser.paragraph1",
                "paragraph2": "pages.cleaning.teaser.paragraph2",
                "learnmore": "pages.cleaning.teaser.learnmore"
            }
        },
        "indoorclimate": {
            "title": "pages.indoorclimate.title",
            "titleshort": "pages.indoorclimate.titleshort",
            "teaser": {
                "subtitle": "pages.indoorclimate.teaser.subtitle",
                "paragraph1": "pages.indoorclimate.teaser.paragraph1",
                "paragraph2title": "pages.indoorclimate.teaser.paragraph2title",
                "paragraph2": "pages.indoorclimate.teaser.paragraph2",
                "paragraph3title": "pages.indoorclimate.teaser.paragraph3title",
                "paragraph3": "pages.indoorclimate.teaser.paragraph3",
                "learnmore": "pages.indoorclimate.teaser.learnmore"
            }
        },
        "realestateutilization": {
            "title": "pages.realestateutilization.title",
            "titleshort": "pages.realestateutilization.titleshort",
            "teaser": {
                "subtitle": "pages.realestateutilization.teaser.subtitle",
                "paragraph1": "pages.realestateutilization.teaser.paragraph1",
                "paragraph2": "pages.realestateutilization.teaser.paragraph2",
                "learnmore": "pages.realestateutilization.teaser.learnmore"
            }
        },
        "deviceconfiguration": {
            "title": "pages.deviceconfiguration.title"
        },
        "motioninsights": {
            "reports": {
                "title": "pages.motioninsights.reports.title",
                "graph": {
                    "occupancy": {
                        "dailytitle": "pages.motioninsights.reports.graph.occupancy.dailytitle",
                        "hourlytitle": "pages.motioninsights.reports.graph.occupancy.hourlytitle",
                        "min": "pages.motioninsights.reports.graph.occupancy.min",
                        "average": "pages.motioninsights.reports.graph.occupancy.average",
                        "max": "pages.motioninsights.reports.graph.occupancy.max"
                    }
                },
                "topdata": {
                    "titlepopular": "pages.motioninsights.reports.topdata.titlepopular",
                    "titlenonpopular": "pages.motioninsights.reports.topdata.titlenonpopular",
                    "type": "pages.motioninsights.reports.topdata.type",
                    "name": "pages.motioninsights.reports.topdata.name",
                    "value": "pages.motioninsights.reports.topdata.value",
                    "day": "pages.motioninsights.reports.topdata.day",
                    "venue": "pages.motioninsights.reports.topdata.venue",
                    "floor": "pages.motioninsights.reports.topdata.floor",
                    "room": "pages.motioninsights.reports.topdata.room",
                    "desk": "pages.motioninsights.reports.topdata.desk",
                    "weekday": "pages.motioninsights.reports.topdata.weekday",
                    "weekday0": "pages.motioninsights.reports.topdata.weekday0",
                    "weekday1": "pages.motioninsights.reports.topdata.weekday1",
                    "weekday2": "pages.motioninsights.reports.topdata.weekday2",
                    "weekday3": "pages.motioninsights.reports.topdata.weekday3",
                    "weekday4": "pages.motioninsights.reports.topdata.weekday4",
                    "weekday5": "pages.motioninsights.reports.topdata.weekday5",
                    "weekday6": "pages.motioninsights.reports.topdata.weekday6"
                },
                "total": {
                    "title": "pages.motioninsights.reports.total.title"
                }
            }
        },
        "comfortinsights": {
            "reports": {
                "title": "pages.comfortinsights.reports.title",
                "graph": {
                    "comfort": {
                        "dailytitle": "pages.comfortinsights.reports.graph.comfort.dailytitle",
                        "hourlytitle": "pages.comfortinsights.reports.graph.comfort.hourlytitle",
                        "min": "pages.comfortinsights.reports.graph.comfort.min",
                        "average": "pages.comfortinsights.reports.graph.comfort.average",
                        "max": "pages.comfortinsights.reports.graph.comfort.max",
                        "thresholdmin": "pages.comfortinsights.reports.graph.comfort.thresholdmin",
                        "thresholdmax": "pages.comfortinsights.reports.graph.comfort.thresholdmax"
                    }
                }
            }
        },
        "sanitaryinsights": {
            "buttons": {
                "percentage": "pages.sanitaryinsights.buttons.percentage",
                "visitors": "pages.sanitaryinsights.buttons.visitors"
            },
            "graph": {
                "dailytitlevisitors": "pages.sanitaryinsights.graph.dailytitlevisitors",
                "hourlytitlevisitors": "pages.sanitaryinsights.graph.hourlytitlevisitors",
                "dailytitlepercentage": "pages.sanitaryinsights.graph.dailytitlepercentage",
                "hourlytitlepercentage": "pages.sanitaryinsights.graph.hourlytitlepercentage",
                "averagelabel": "pages.sanitaryinsights.graph.averagelabel",
                "visitorsexplanation": "pages.sanitaryinsights.graph.visitorsexplanation"
            }
        },
        "noshowinsights": {
            "overview": {
                "noshowdescriptionpartone": "pages.noshowinsights.overview.noshowdescriptionpartone",
                "noshowdescriptionparttwo": "pages.noshowinsights.overview.noshowdescriptionparttwo",
                "noshowdescriptionlink": "pages.noshowinsights.overview.noshowdescriptionlink",
                "table": {
                    "title": "pages.noshowinsights.overview.table.title",
                    "room": "pages.noshowinsights.overview.table.room",
                    "lastnoshow": "pages.noshowinsights.overview.table.lastnoshow",
                    "floor": "pages.noshowinsights.overview.table.floor",
                    "numberofnoshows": "pages.noshowinsights.overview.table.numberofnoshows",
                },
                "averages": {
                    "title": "pages.noshowinsights.overview.averages.title",
                    "total": "pages.noshowinsights.overview.averages.total",
                    "percentageofreservations": "pages.noshowinsights.overview.averages.percentageofreservations",
                    "outliers": "pages.noshowinsights.overview.averages.outliers",
                    "mostnoshows": "pages.noshowinsights.overview.averages.mostnoshows",
                    "noshowtimes": "pages.noshowinsights.overview.averages.noshowtimes",
                    "averagenoshows": "pages.noshowinsights.overview.averages.averagenoshows",
                    "noshowinterval": "pages.noshowinsights.overview.averages.noshowinterval",
                    "noshowtimebetween": "pages.noshowinsights.overview.averages.noshowtimebetween",
                    "shows": "pages.noshowinsights.overview.averages.shows",
                    "workspaces": "pages.noshowinsights.overview.averages.workspaces",
                    "noshows": "pages.noshowinsights.overview.averages.noshows",
                    "intervallabels": {
                        "threemonths": "pages.noshowinsights.overview.averages.intervallabels.threemonths",
                        "onemonth": "pages.noshowinsights.overview.averages.intervallabels.onemonth",
                        "oneweek": "pages.noshowinsights.overview.averages.intervallabels.oneweek",
                        "morethanoneday": "pages.noshowinsights.overview.averages.intervallabels.morethanoneday",
                        "lessthanoneday": "pages.noshowinsights.overview.averages.intervallabels.lessthanoneday",
                    }
                }
            }
        },
        "documentsinsights": {
            "title": "pages.documentsinsights.title",
            "downloadbutton": "pages.documentsinsights.downloadbutton",
            "nodocumentsfound": "pages.documentsinsights.nodocumentsfound",
            "table": {
                "title": "pages.documentsinsights.table.title",
                "datetime": "pages.documentsinsights.table.datetime",
                "download": "pages.documentsinsights.table.download"
            }
        },
        "heatmap": {
            "occupancy": "pages.heatmap.occupancy",
            "occupation_percentage": "pages.heatmap.occupation_percentage",
            "abnormal_values": "pages.heatmap.abnormal_values",
            "abnormal_values_header": "pages.heatmap.abnormal_values_header",
            "all_data": "pages.heatmap.all_data",
            "table": {
                "asset_name": "pages.heatmap.table.asset_name",
                "occupancy": "pages.heatmap.table.occupancy",
                "humidity": "pages.heatmap.table.humidity",
                "no_values_found": "pages.heatmap.table.no_values_found"
            }
        },
        "energy": {
            "title": "pages.energy.title",
            "titleshort": "pages.energy.titleshort",
            "teaser": {
                "title": "pages.energy.teaser.title",
                "subtitle": "pages.energy.teaser.subtitle",
                "paragraph1": "pages.energy.teaser.paragraph1",
                "paragraph2": "pages.energy.teaser.paragraph2",
                "learnmore": "pages.energy.teaser.learnmore"
            },
            "overview": {
                "title": "pages.energy.overview.title",
                "columns": {
                    "venue": "pages.energy.overview.columns.venue",
                    "contract": "pages.energy.overview.columns.contract",
                    "yearlyconsumedenergy": "pages.energy.overview.columns.yearlyconsumedenergy",
                    "yearlyconsumedgas": "pages.energy.overview.columns.yearlyconsumedgas"
                },
                "contract": {
                    "years": "pages.energy.overview.contract.years",
                    "months": "pages.energy.overview.contract.months",
                    "days": "pages.energy.overview.contract.days",
                    "still": "pages.energy.overview.contract.still",
                    "expired": "pages.energy.overview.contract.expired"
                }
            },
            "tabs": {
                "backbutton": "pages.energy.tabs.backbutton",
                "overview": {
                    "energyconsumption": "pages.energy.tabs.overview.energyconsumption",
                    "meteroverview": "pages.energy.tabs.overview.meteroverview",
                    "gasmeters": "pages.energy.tabs.overview.gasmeters",
                    "heatmeters": "pages.energy.tabs.overview.heatmeters",
                    "electricmeters": "pages.energy.tabs.overview.electricmeters",
                    "usage": "pages.energy.tabs.overview.usage",
                    "mainmeter": "pages.energy.tabs.overview.mainmeter",
                    "mainmeters": "pages.energy.tabs.overview.mainmeters",
                    "details": "pages.energy.tabs.overview.details",
                    "contractinformation": {
                        "title": "pages.energy.tabs.overview.contractinformation.title",
                        "start": "pages.energy.tabs.overview.contractinformation.start",
                        "end": "pages.energy.tabs.overview.contractinformation.end",
                        "duration": "pages.energy.tabs.overview.contractinformation.duration",
                        "contact": "pages.energy.tabs.overview.contractinformation.contact",
                        "deal": "pages.energy.tabs.overview.contractinformation.deal",
                        "contract": "pages.energy.tabs.overview.contractinformation.contract",
                        "subtitles": {
                            "start": "pages.energy.tabs.overview.contractinformation.subtitles.start",
                            "end": "pages.energy.tabs.overview.contractinformation.subtitles.end",
                            "year": "pages.energy.tabs.overview.contractinformation.subtitles.year",
                            "and": "pages.energy.tabs.overview.contractinformation.subtitles.and",
                            "month": "pages.energy.tabs.overview.contractinformation.subtitles.month",
                            "months": "pages.energy.tabs.overview.contractinformation.subtitles.months",
                            "duration": "pages.energy.tabs.overview.contractinformation.subtitles.duration",
                            "deal": "pages.energy.tabs.overview.contractinformation.subtitles.deal",
                            "gasdeal": "pages.energy.tabs.overview.contractinformation.subtitles.gasdeal",
                            "electricitydeal": "pages.energy.tabs.overview.contractinformation.subtitles.electricitydeal",
                            "contract": "pages.energy.tabs.overview.contractinformation.subtitles.contract"
                        }
                    },
                    "venuesavings": {
                        "totalsavingscontractyear": "pages.energy.tabs.overview.venuesavings.totalsavingscontractyear",
                        "electricoverviewcontractyear": "pages.energy.tabs.overview.venuesavings.electricoverviewcontractyear",
                        "gasoverviewcontractyear": "pages.energy.tabs.overview.venuesavings.gasoverviewcontractyear",
                        "heatoverviewcontractyear": "pages.energy.tabs.overview.venuesavings.heatoverviewcontractyear",
                        "totalenergysavingsuntilnow": "pages.energy.tabs.overview.venuesavings.totalenergysavingsuntilnow",
                        "indicationcostreduction": "pages.energy.tabs.overview.venuesavings.indicationcostreduction",
                        "co2reduction": "pages.energy.tabs.overview.venuesavings.co2reduction",
                        "electricitysavings": "pages.energy.tabs.overview.venuesavings.electricitysavings",
                        "electricityconsumption": "pages.energy.tabs.overview.venuesavings.electricityconsumption",
                        "gassavings": "pages.energy.tabs.overview.venuesavings.gassavings",
                        "gasconsumption": "pages.energy.tabs.overview.venuesavings.gasconsumption",
                        "heatsavings": "pages.energy.tabs.overview.venuesavings.heatsavings",
                        "heatconsumption": "pages.energy.tabs.overview.venuesavings.heatconsumption"
                    }
                },
                "consumption": {
                    "energytitle": "pages.energy.tabs.consumption.energytitle",
                    "gastitle": "pages.energy.tabs.consumption.gastitle",
                    "heattitle": "pages.energy.tabs.consumption.heattitle",
                    "coldtitle": "pages.energy.tabs.consumption.coldtitle",
                    "allassets": "pages.energy.tabs.consumption.allassets"
                },
                "temperature": {
                    "graphtitle": "pages.energy.tabs.temperature.graphtitle",
                    "openinghoursonly": "pages.energy.tabs.temperature.openinghoursonly",
                    "gas": "pages.energy.tabs.temperature.gas",
                    "electricity": "pages.energy.tabs.temperature.electricity",
                    "heat": "pages.energy.tabs.temperature.heat",
                    "cold": "pages.energy.tabs.temperature.cold",
                    "gasoutsideopeninghours": "pages.energy.tabs.temperature.gasoutsideopeninghours",
                    "electricityoutsideopeninghours": "pages.energy.tabs.temperature.electricityoutsideopeninghours",
                    "heatoutsideopeninghours": "pages.energy.tabs.temperature.heatoutsideopeninghours",
                    "coldoutsideopeninghours": "pages.energy.tabs.temperature.coldoutsideopeninghours"
                },
                "savings": {
                    "savingstitle": "pages.energy.tabs.savings.savingstitle",
                    "electricitysavingstitle": "pages.energy.tabs.savings.electricitysavingstitle",
                    "gassavingstitle": "pages.energy.tabs.savings.gassavingstitle",
                    "percentagesavingstitle": "pages.energy.tabs.savings.percentagesavingstitle",
                    "co2savingstitle": "pages.energy.tabs.savings.co2savingstitle",
                    "heatsavingstitle": "pages.energy.tabs.savings.heatsavingstitle"
                },
                "graph": {
                    "compensatedusage": "pages.energy.tabs.graph.compensatedusage",
                    "compensatedusagebelowprognosis": "pages.energy.tabs.graph.compensatedusagebelowprognosis",
                    "compensatedusagebelowbaseline": "pages.energy.tabs.graph.compensatedusagebelowbaseline",
                    "compensatedusageabovebaseline": "pages.energy.tabs.graph.compensatedusageabovebaseline",
                    "compensatedusagenobaseline": "pages.energy.tabs.graph.compensatedusagenobaseline",
                    "actualusage": "pages.energy.tabs.graph.actualusage",
                    "actualusagebelowprognosis": "pages.energy.tabs.graph.actualusagebelowprognosis",
                    "actualusagebelowbaseline": "pages.energy.tabs.graph.actualusagebelowbaseline",
                    "actualusageabovebaseline": "pages.energy.tabs.graph.actualusageabovebaseline",
                    "actualusagenobaseline": "pages.energy.tabs.graph.actualusagenobaseline",
                    "prognosisusage": "pages.energy.tabs.graph.prognosisusage",
                    "baseline": "pages.energy.tabs.graph.baseline",
                    "temperature": "pages.energy.tabs.graph.temperature",
                    "temperaturedescription": "pages.energy.tabs.graph.temperaturedescription",
                    "datatype": {
                        "electricity": "pages.energy.tabs.graph.datatype.electricity",
                        "gas": "pages.energy.tabs.graph.datatype.gas",
                        "heat": "pages.energy.tabs.graph.datatype.heat",
                        "cold": "pages.energy.tabs.graph.datatype.cold"
                    }
                }
            },
            "documents": {
                "table": {
                    "title": "pages.energy.documents.table.title",
                    "datetime": "pages.energy.documents.table.datetime",
                    "download": "pages.energy.documents.table.download",
                    "author": "pages.energy.documents.table.author"
                },
                "notfound": "pages.energy.documents.notfound"
            }
        },
        "documents": {
            "table": {
                "title": "pages.documents.table.title",
                "datetime": "pages.documents.table.datetime",
                "service": "pages.documents.table.service",
                "download": "pages.documents.table.download",
                "author": "pages.documents.table.author"
            },
            "services": {
                "general": "pages.documents.services.general",
                "energymanagement": "pages.documents.services.energymanagement",
                "realestateutilization": "pages.documents.services.realestateutilization",
                "indoorclimate": "pages.documents.services.indoorclimate",
                "cleaning": "pages.documents.services.cleaning"
            },
            "notfound": "pages.energy.documents.notfound"
        },
        "notifications": {
            "category": "pages.notifications.category",
            "datenotification": "pages.notifications.datenotification",
            "datecompleet": "pages.notifications.datecompleet",
            "notificationby": "pages.notifications.notificationby",
            "venue": "pages.notifications.venue",
            "asset": "pages.notifications.asset",
            "status": "pages.notifications.status",
            "images": "pages.notifications.images",
            "description": "pages.notifications.description",
            "replies": "pages.notifications.replies",
            "reply": "pages.notifications.reply",
            "zone": "pages.notifications.zone",
            "notifications": "pages.notifications.notifications",
            "showall": "pages.notifications.showall",
            "createdon": "pages.notifications.createdon",
            "resolvedon": "pages.notifications.resolvedon",
            "space": "pages.notifications.space",
            "resolved": "pages.notifications.resolved",
            "notresolved": "pages.notifications.notresolved",
            "comfortnotifications": "pages.notifications.comfortnotifications",
            "cleaningnotifications": "pages.notifications.cleaningnotifications",
            "daytimeexplanation": "pages.notifications.daytimeexplanation",
            "daytimeexplanation2": "pages.notifications.daytimeexplanation2",
            "configureddaytime": "pages.notifications.configureddaytime",
            "shownotificationsindaytime": "pages.notifications.shownotificationsindaytime",
            "showallnotifications": "pages.notifications.showallnotifications",
            "changedaytime": "pages.notifications.changedaytime",
            "nodaysselected": "pages.notifications.nodaysselected",
            "alldaysselected": "pages.notifications.alldaysselected",
            "time": "pages.notifications.time",
            "statusnames": {
                "open": "pages.notifications.statusnames.open",
                "actionrequired": "pages.notifications.statusnames.actionrequired",
                "signedoff": "pages.notifications.statusnames.signedoff",
                "rejected": "pages.notifications.statusnames.rejected",
                "none": "pages.notifications.statusnames.none",
                "inprogress": "pages.notifications.statusnames.inprogress"
            },
            "norawdatafound": "pages.notifications.norawdatafound",
            "errormessageid": "pages.notifications.errormessageid",
            "respond": "pages.notifications.respond",
            "responsedialog": {
                "title": "pages.notifications.responsedialog.title",
                "placeholder": "pages.notifications.responsedialog.placeholder",
                "sendresponse": "pages.notifications.responsedialog.sendresponse",
                "responsesend": "pages.notifications.responsedialog.responsesend",
                "close": "pages.notifications.responsedialog.close"
            },
            "thresholdexpand": {
                "exceedance": "pages.notifications.thresholdExpand.exceedance",
                "undershoot": "pages.notifications.thresholdExpand.undershoot",
                "measuredvalue": "pages.notifications.thresholdExpand.measuredvalue",
                "thresholdvalue": "pages.notifications.thresholdExpand.thresholdvalue"
            },
            "sharedexpand": {
                "createdby": "pages.notifications.sharedexpand.createdby",
                "createdon": "pages.notifications.sharedexpand.createdon",
                "resolvedon": "pages.notifications.sharedexpand.resolvedon",
                "venuename": "pages.notifications.sharedexpand.venuename",
                "spacename": "pages.notifications.sharedexpand.spacename",
                "status": "pages.notifications.sharedexpand.status",
                "description": "pages.notifications.sharedexpand.description"
            }
        },
        "settings": {
            "realestateutilization": {
                "availability": "pages.settings.realestateutilization.availability",
                "workspacereservations": "pages.settings.realestateutilization.workspacereservations",
                "roomreservations": "pages.settings.realestateutilization.roomreservations",
                "general": "pages.settings.realestateutilization.general",
                "tags": "pages.settings.realestateutilization.tags",
                "paperbins": "pages.settings.realestateutilization.paperbins",
                "toilets": "pages.settings.realestateutilization.toilets",
            },
            "comfortthresholds": {
                "title": "pages.settings.comfortthresholds.title",
                "comfortvalues": "pages.settings.comfortthresholds.comfortvalues",
                "temperature": "pages.settings.comfortthresholds.temperature",
                "humidity": "pages.settings.comfortthresholds.humidity",
                "carbondioxide": "pages.settings.comfortthresholds.carbondioxide",
                "sound": "pages.settings.comfortthresholds.sound",
                "light": "pages.settings.comfortthresholds.header",
                "receivenotifications": "pages.settings.comfortthresholds.receivenotifications",
                "enableblenotifications": "pages.settings.comfortthresholds.enableblenotifications",
                "sendnotificationsto": "pages.settings.comfortthresholds.sendnotificationsto",
                "setthresholds": "pages.settings.comfortthresholds.setthresholds",
                "saved": "pages.settings.comfortthresholds.saved",
                "erroronsave": "pages.settings.comfortthresholds.erroronsave",
                "cancel": "pages.settings.comfortthresholds.cancel",
                "save": "pages.settings.comfortthresholds.save",
                "selectdays": "pages.settings.comfortthresholds.selectdays",
                "selecttimeframe": "pages.settings.comfortthresholds.selecttimeframe",
                "invalidstartendtimecombination": "pages.settings.comfortthresholds.invalidstartendtimecombination",
                "receivenotification": "pages.settings.comfortthresholds.receivenotification"
            },
            "cleaning": {
                "title": "pages.settings.cleaning.title",
                "toiletstab": {
                    "title": "pages.settings.cleaning.toiletstab.title",
                    "instruction": "pages.settings.cleaning.toiletstab.instruction",
                    "notification": "pages.settings.cleaning.toiletstab.notification",
                    "nonotification": "pages.settings.cleaning.toiletstab.nonotification",
                    "cancel": "pages.settings.cleaning.toiletstab.cancel",
                    "save": "pages.settings.cleaning.toiletstab.save",
                    "sendnotification": "pages.settings.cleaning.toiletstab.sendnotification",
                    "threshold": "pages.settings.cleaning.toiletstab.threshold",
                    "applytoall": "pages.settings.cleaning.toiletstab.applytoall",
                    "nosubscription": "pages.settings.cleaning.toiletstab.nosubscription"
                },
                "paperbinstab": {
                    "title": "pages.settings.cleaning.paperbinstab.title",
                    "instruction": "pages.settings.cleaning.paperbinstab.instruction",
                    "notification": "pages.settings.cleaning.paperbinstab.notification",
                    "nonotification": "pages.settings.cleaning.paperbinstab.nonotification",
                    "nosubscription": "pages.settings.cleaning.paperbinstab.nosubscription",
                    "cancel": "pages.settings.cleaning.paperbinstab.cancel",
                    "save": "pages.settings.cleaning.paperbinstab.save",
                    "sendnotification": "pages.settings.cleaning.paperbinstab.sendnotification",
                    "threshold": "pages.settings.cleaning.paperbinstab.threshold",
                    "applytoall": "pages.settings.cleaning.paperbinstab.applytoall",
                }
            },
            "workspaces": {
                "title": "pages.settings.workspaces.title",
                "save": "pages.settings.workspaces.save",
                "cancel": "pages.settings.workspaces.cancel",
                "reservable": "pages.settings.workspaces.reservable",
                "unreservable": "pages.settings.workspaces.unreservable",
                "changessaved": "pages.settings.workspaces.changessaved",
                "erroronsave": "pages.settings.workspaces.erroronsave",
                "availability": "pages.settings.workspaces.availability",
                "instruction": "pages.settings.workspaces.instruction",
                "comfortsettings": "pages.settings.workspaces.comfortsettings",
                "comfortsettingsdescription": "pages.settings.workspaces.comfortsettingsdescription",
                "noshowdefinition": "pages.settings.workspaces.noshowdefinition",
                "noshowafter": "pages.settings.workspaces.noshowafter",
                "automaticcancellationsettings": "pages.settings.workspaces.automaticcancellationsettings",
                "settingsdescription": "pages.settings.workspaces.settingsdescription",
                "generaltab": {
                    "title": "pages.settings.workspaces.generaltab.title",
                    "saved": "pages.settings.workspaces.generaltab.saved",
                    "erroronsave": "pages.settings.workspaces.generaltab.erroronsave",
                    "thresholds": "pages.settings.workspaces.generaltab.thresholds",
                    "from": "pages.settings.workspaces.generaltab.from",
                    "until": "pages.settings.workspaces.generaltab.until",
                    "slider": "pages.settings.workspaces.generaltab.slider",
                    "inadvance": "pages.settings.workspaces.generaltab.inadvance",
                    "release": "pages.settings.workspaces.generaltab.release",
                    "ignorerelease": "pages.settings.workspaces.generaltab.ignorerelease",
                    "starttimeplaceholder": "pages.settings.workspaces.generaltab.starttimeplaceholder",
                    "endtimeplaceholder": "pages.settings.workspaces.generaltab.endtimeplaceholder",
                    "invalidtime": "pages.settings.workspaces.generaltab.invalidtime",
                    "invalidaadgroupid": "pages.settings.workspaces.generaltab.invalidaadgroupid",
                    "emptyaadgroupname": "pages.settings.workspaces.generaltab.emptyaadgroupname",
                    "labels": {
                        "day": "pages.settings.workspaces.generaltab.labels.day",
                        "days": "pages.settings.workspaces.generaltab.labels.days",
                        "week": "pages.settings.workspaces.generaltab.labels.week",
                        "weeks": "pages.settings.workspaces.generaltab.labels.weeks",
                        "month": "pages.settings.workspaces.generaltab.labels.month",
                        "months": "pages.settings.workspaces.generaltab.labels.months",
                        "minutes": "pages.settings.workspaces.generaltab.labels.minutes",
                        "never": "pages.settings.workspaces.generaltab.labels.never",
                    },
                    "weekdays": {
                        "weekday0": "pages.settings.workspaces.generaltab.weekdays.weekday0",
                        "weekday1": "pages.settings.workspaces.generaltab.weekdays.weekday1",
                        "weekday2": "pages.settings.workspaces.generaltab.weekdays.weekday2",
                        "weekday3": "pages.settings.workspaces.generaltab.weekdays.weekday3",
                        "weekday4": "pages.settings.workspaces.generaltab.weekdays.weekday4",
                        "weekday5": "pages.settings.workspaces.generaltab.weekdays.weekday5",
                        "weekday6": "pages.settings.workspaces.generaltab.weekdays.weekday6",
                        "weekdaysall": "pages.settings.workspaces.generaltab.weekdays.weekdaysall"
                    },
                    "deletepopup": {
                        "delete": "pages.settings.workspaces.generaltab.deletepopup.delete",
                        "cancel": "pages.settings.workspaces.generaltab.deletepopup.cancel",
                        "header": "pages.settings.workspaces.generaltab.deletepopup.header",
                        "questionfirstpart": "pages.settings.workspaces.generaltab.deletepopup.questionfirstpart",
                        "questionsecondpart": "pages.settings.workspaces.generaltab.deletepopup.questionsecondpart"
                    }
                },
                "availabilitytab": {
                    "title": "pages.settings.workspaces.availabilitytab.title"
                },
                "typetab": {
                    "title": "pages.settings.workspaces.typetab.title",
                    "explanation": "pages.settings.workspaces.typetab.explanation",
                    "activetags": "pages.settings.workspaces.typetab.activetags",
                    "tagname": "pages.settings.workspaces.typetab.tagname",
                    "workspacescount": "pages.settings.workspaces.typetab.workspacescount",
                    "tagscount": "pages.settings.workspaces.typetab.tagscount",
                    "lastmodified": "pages.settings.workspaces.typetab.lastmodified",
                    "selected": "pages.settings.workspaces.typetab.selected",
                    "otheroption": "pages.settings.workspaces.typetab.otheroption",
                    "nooption": "pages.settings.workspaces.typetab.nooption",
                    "successdelete": "pages.settings.workspaces.typetab.successdelete",
                    "faildelete": "pages.settings.workspaces.typetab.faildelete",
                    "newtag": "pages.settings.workspaces.typetab.newtag",
                    "overwritetag": "pages.settings.workspaces.typetab.overwritetag",
                    "deleteconfirm": "pages.settings.workspaces.typetab.deleteconfirm",
                    "notagsdata": "pages.settings.workspaces.typetab.notagsdata",
                    "workspacetype": "pages.settings.workspaces.typetab.workspacetype",
                    "deleteexplanation": "pages.settings.workspaces.typetab.deleteexplanation",
                    "deletepopup": {
                        "questionfirstpart": "pages.settings.workspaces.typetab.deletepopup.questionfirstpart",
                        "questionsecondpart": "pages.settings.workspaces.typetab.deletepopup.questionsecondpart",
                        "delete": "pages.settings.workspaces.typetab.deletepopup.delete"
                    },
                    "popup": {
                        "assignworkspace": "pages.settings.workspaces.typetab.popup.assignworkspace",
                        "selectedworkspaces": "pages.settings.workspaces.typetab.popup.selectedworkspaces",
                        "selecttagtype": "pages.settings.workspaces.typetab.popup.selecttagtype",
                        "selecttagname": "pages.settings.workspaces.typetab.popup.selecttagname",
                        "dropdownempty": "pages.settings.workspaces.typetab.popup.dropdownempty",
                        "selectanoption": "pages.settings.workspaces.typetab.popup.selectanoption",
                        "successave": "pages.settings.workspaces.typetab.popup.successave",
                        "failsave": "pages.settings.workspaces.typetab.popup.failsave",
                        "save": "pages.settings.workspaces.typetab.popup.save",
                        "cancel": "pages.settings.workspaces.typetab.popup.cancel"
                    }
                }
            },
            "roomreservations": {
                "roomsettingstitle": "pages.settings.roomreservations.roomsettingstitle",
                "settingtitle": "pages.settings.roomreservations.settingtitle",
                "settingsdescription": "pages.settings.roomreservations.settingsdescription",
                "description": "pages.settings.roomreservations.description",
                "noshowdescription": {
                    "start": "pages.settings.roomreservations.noshowdescription.start",
                    "link": "pages.settings.roomreservations.noshowdescription.link",
                    "end": "pages.settings.roomreservations.noshowdescription.end"
                },
                "automaticcancellationprefix": "pages.settings.roomreservations.automaticcancellationprefix",
                "automaticcancellationsuffix": "pages.settings.roomreservations.automaticcancellationsuffix",
                "automaticcancellationafter": "pages.settings.roomreservations.automaticcancellationafter",
                "floorlevelplaceholder": "pages.settings.roomreservations.floorlevelplaceholder",
                "roomnameplaceholder": "pages.settings.roomreservations.roomnameplaceholder",
                "yesoption": "pages.settings.roomreservations.yesoption",
                "nooption": "pages.settings.roomreservations.nooption",
                "dayabbreviations": "pages.settings.roomreservations.dayabbreviations",
                "days": "pages.settings.roomreservations.days",
                "floorlevel": "pages.settings.roomreservations.floorlevel",
                "roomname": "pages.settings.roomreservations.roomname",
                "photo": "pages.settings.roomreservations.photo",
                "customphoto": "pages.settings.roomreservations.customphoto",
                "defaultphoto": "pages.settings.roomreservations.defaultphoto",
                "capacity": "pages.settings.roomreservations.capacity",
                "facilities": "pages.settings.roomreservations.facilities",
                "reservationenabled": "pages.settings.roomreservations.reservationenabled",
                "availability": "pages.settings.roomreservations.availability",
                "timeframe": "pages.settings.roomreservations.timeframe",
                "builduptime": "pages.settings.roomreservations.builduptime",
                "winddowntime": "pages.settings.roomreservations.winddowntime",
                "photouploaderror": "pages.settings.roomreservations.photouploaderror",
                "browsephoto": "pages.settings.roomreservations.browsephoto",
                "photosaved": "pages.settings.roomreservations.photosaved",
                "phototoobigerror": "pages.settings.roomreservations.phototoobigerror",
                "photowrongorientationerror": "pages.settings.roomreservations.photowrongorientationerror",
                "photodeleteconfirmationbydeleteclick": "pages.settings.roomreservations.photodeleteconfirmationbydeleteclick",
                "photodeleteconfirmationbydefaultphotochange": "pages.settings.roomreservations.photodeleteconfirmationbydefaultphotochange",
                "photodeleted": "pages.settings.roomreservations.photodeleted",
                "photodeletederror": "pages.settings.roomreservations.photodeletederror",
                "saved": "pages.settings.roomreservations.saved",
                "erroronsave": "pages.settings.roomreservations.erroronsave",
                "invalidcapacity": "pages.settings.roomreservations.invalidcapacity"
            }
        }
    },
    "switchappmenu":{
        "bereservationsapp": "switchappmenu.bereservationsapp",
        "beequippedbevitalreports": "switchappmenu.beequippedbevitalreports",
        "switchapp": "switchappmenu.switchapp",
        "bedashboard": "switchappmenu.bedashboard"
    },
    "usersettingsmenu": {
        "welcome": "usersettingsmenu.welcome",
        "logout": "usersettingsmenu.logout",
        "language": "usersettingsmenu.language",
        "account": "usersettingsmenu.account",
        "languages": {
            "dutch": "usersettingsmenu.languages.dutch",
            "english": "usersettingsmenu.languages.english"
        }
    },
    "notifications": {
        "invalidfloorspaces": "notifications.invalidfloorspaces"
    },
    "filtermenu": {
        "nooptions": "filtermenu.nooptions"
    },
    "mapdetails": {
        "unoccupied": "mapdetails.unoccupied",
        "occupied": "mapdetails.occupied",
        "coolingdown": "mapdetails.coolingdown",
        "room": "mapdetails.room",
        "rooms": "mapdetails.rooms",
        "workspace": "mapdetails.workspace",
        "workspaces": "mapdetails.workspaces"
    },
    "mappopup": {
        "loadingtext": "mappopup.loadingtext",
        "nodata": "mappopup.nodata",
        "roomisfree": "mappopup.roomisfree",
        "workspaceisfree": "mappopup.workspaceisfree",
        "roomisoccupied": "mappopup.roomisoccupied",
        "workspaceisoccupied": "mappopup.workspaceisoccupied",
        "roomispossiblyfree": "mappopup.roomispossiblyfree",
        "workspaceispossiblyfree": "mappopup.workspaceispossiblyfree",
        "sensor": {
            "occupancystatus": {
                "title": "mappopup.sensor.occupancystatus.title",
                "unoccupied": "mappopup.sensor.occupancystatus.unoccupied",
                "occupied": "mappopup.sensor.occupancystatus.occupied",
                "coolingdown": "mappopup.sensor.occupancystatus.coolingdown",
                "symbol": "mappopup.sensor.occupancystatus.symbol"
            },
            "motion": {
                "title": "mappopup.sensor.motion.title",
                "false": "mappopup.sensor.motion.false",
                "true": "mappopup.sensor.motion.true",
                "symbol": "mappopup.sensor.motion.symbol"
            },
            "humidity": {
                "title": "mappopup.sensor.humidity.title",
                "symbol": "mappopup.sensor.humidity.symbol"
            },
            "sound": {
                "title": "mappopup.sensor.sound.title",
                "symbol": "mappopup.sensor.sound.symbol"
            },
            "light": {
                "title": "mappopup.sensor.light.title",
                "symbol": "mappopup.sensor.light.symbol"
            },
            "temperature": {
                "title": "mappopup.sensor.temperature.title",
                "symbol": "mappopup.sensor.temperature.symbol"
            },
            "carbondioxide": {
                "title": "mappopup.sensor.carbondioxide.title",
                "symbol": "mappopup.sensor.carbondioxide.symbol"
            },
            "peoplecount": {
                "title": "mappopup.sensor.peoplecount.title",
                "symbol": "mappopup.sensor.peoplecount.symbol"
            },
            "nrofpeopleentering": {
                "title": "mappopup.sensor.nrofpeopleentering.title",
                "symbol": "mappopup.sensor.nrofpeopleentering.symbol"
            },
            "nrofpeopleleaving": {
                "title": "mappopup.sensor.nrofpeopleleaving.title",
                "symbol": "mappopup.sensor.nrofpeopleleaving.symbol"
            }
        },
        "buttons": {
            "insights": "mappopup.buttons.insights"
        }
    },
    "threshold-settings-modal": {
        "modal-title": "threshold-settings-modal.modal-title",
        "set-threshold": "threshold-settings-modal.set-threshold",
        "visability-threshold": "threshold-settings-modal.visability-threshold",
        "maximum-threshold": "threshold-settings-modal.maximum-threshold",
        "minimum-threshold": "threshold-settings-modal.minimum-threshold",
        "reset-values": "threshold-settings-modal.reset-values",
        "save": "threshold-settings-modal.save",
        "cancel": "threshold-settings-modal.cancel",
        "adjust-threshold": "threshold-settings-modal.adjust-threshold"
    },
    "global-error-messages": {
        "no-venues-configured-for-account": "global-error-messages.no-venues-configured-for-account",
        "account-configuration-incorrect": "global-error-messages.account-configuration-incorrect"
    },
    "adminmessagebar": {
        "adminmessagehidden": "adminmessagebar.adminmessagehidden",
        "new-app-version": "adminmessagebar.new-app-version",
        "updatesucces": "adminmessagebar.updatesucces",
    },
    "tooltips": {
        "energy": {
            "top-venue-savings": {
                "currentsavings-euro": "tooltips.energy.top-venue-savings.currentsavings-euro",
                "currentsavings-percentage": "tooltips.energy.top-venue-savings.currentsavings-percentage",
                "savingstarget": "tooltips.energy.top-venue-savings.savingstarget"
            },
            "top-venue-savings-energy": {
                "currentsavings-kwh": "tooltips.energy.top-venue-savings-energy.currentsavings-kwh",
                "currentsavings-percentage": "tooltips.energy.top-venue-savings-energy.currentsavings-percentage"
            },
            "top-venue-savings-gas": {
                "currentsavings-m3": "tooltips.energy.top-venue-savings-gas.currentsavings-m3",
                "currentsavings-percentage": "tooltips.energy.top-venue-savings-gas.currentsavings-percentage"
            },
            "top-venue-savings-heat": {
                "currentsavings-gj": "tooltips.energy.top-venue-savings-heat.currentsavings-gj",
                "currentsavings-percentage": "tooltips.energy.top-venue-savings-heat.currentsavings-percentage"
            },
            "managementoverview": {
                "total": {
                    "costs": "tooltips.energy.managementoverview.total.costs",
                    "electricity": "tooltips.energy.managementoverview.total.electricity",
                    "gas": "tooltips.energy.managementoverview.total.gas",
                    "heat": "tooltips.energy.managementoverview.total.heat",
                },
                "this-year": {
                    "costs": "tooltips.energy.managementoverview.this-year.costs",
                    "electricity": "tooltips.energy.managementoverview.this-year.electricity",
                    "gas": "tooltips.energy.managementoverview.this-year.gas",
                    "heat": "tooltips.energy.managementoverview.this-year.heat"
                },
                "savings": {
                    "solarpanels": "tooltips.energy.managementoverview.savings.solarpanels",
                    "trees": "tooltips.energy.managementoverview.savings.trees",
                    "co2": "tooltips.energy.managementoverview.savings.co2",
                    "totalpercentage": "tooltips.energy.managementoverview.savings.totalpercentage"
                }
            },
            "venue-energy-savings-content": {
                "savings-percentage": "tooltips.energy.venue-energy-savings-content.savings-percentage",
                "savings-cost": "tooltips.energy.venue-energy-savings-content.savings-cost",
                "savings-co2": "tooltips.energy.venue-energy-savings-content.savings-co2",
                "savings-kwh": "tooltips.energy.venue-energy-savings-content.savings-kwh",
                "savings-m3": "tooltips.energy.venue-energy-savings-content.savings-m3",
                "savings-heat": "tooltips.energy.venue-energy-savings-content.savings-heat"
            },
            "asset-tree": {
                "usage-kwh": "tooltips.energy.asset-tree.usage-kwh",
                "usage-kwh-percentage": "tooltips.energy.asset-tree.usage-kwh-percentage",
                "usage-m3": "tooltips.energy.asset-tree.usage-m3",
                "usage-m3-percentage": "tooltips.energy.asset-tree.usage-m3-percentage",
                "usage-gj": "tooltips.energy.asset-tree.usage-gj",
                "usage-gj-percentage": "tooltips.energy.asset-tree.usage-gj-percentage",
            },
            "venue-energy-consumption-content": {
                "usage-gas": "tooltips.energy.venue-energy-consumption-content.usage-gas",
                "usage-electricity": "tooltips.energy.venue-energy-consumption-content.usage-electricity",
                "usage-heat": "tooltips.energy.venue-energy-consumption-content.usage-heat",
                "usage-cold": "tooltips.energy.venue-energy-consumption-content.usage-cold",
                "prognosis-usage-gas": "tooltips.energy.venue-energy-consumption-content.prognosis-usage-gas",
                "prognosis-usage-electricity": "tooltips.energy.venue-energy-consumption-content.prognosis-usage-electricity",
                "prognosis-usage-heat": "tooltips.energy.venue-energy-consumption-content.prognosis-usage-heat",
                "baseline-usage-gas": "tooltips.energy.venue-energy-consumption-content.baseline-usage-gas",
                "baseline-usage-electricity": "tooltips.energy.venue-energy-consumption-content.baseline-usage-electricity",
                "baseline-usage-heat": "tooltips.energy.venue-energy-consumption-content.baseline-usage-heat",
                "compensated-usage-gas": "tooltips.energy.venue-energy-consumption-content.compensated-usage-gas",
                "compensated-usage-electricity": "tooltips.energy.venue-energy-consumption-content.compensated-usage-electricity",
            },
            "energy-portfolio": {
                "subscription": "tooltips.energy.energy-portfolio.subscription",
                "contract": {
                    "year-savings": "tooltips.energy.energy-portfolio.contract.year-savings",
                    "savings-target": "tooltips.energy.energy-portfolio.contract.savings-target"
                },
                "currency": {
                    "year-savings": "tooltips.energy.energy-portfolio.currency.year-savings",
                    "totalsavings": "tooltips.energy.energy-portfolio.currency.totalsavings"
                },
                "electricity": {
                    "year-savings": "tooltips.energy.energy-portfolio.electricity.year-savings",
                    "totalsavings": "tooltips.energy.energy-portfolio.electricity.totalsavings"
                },
                "gas": {
                    "year-savings": "tooltips.energy.energy-portfolio.gas.year-savings",
                    "totalsavings": "tooltips.energy.energy-portfolio.gas.totalsavings"
                },
                "heat": {
                    "year-savings": "tooltips.energy.energy-portfolio.heat.year-savings",
                    "totalsavings": "tooltips.energy.energy-portfolio.heat.totalsavings"
                }
            },
            "venue-savings": {
                "totalenergysavingsuntilnow": "tooltips.energy.venue-savings.totalenergysavingsuntilnow",
                "indicationcostreduction": "tooltips.energy.venue-savings.indicationcostreduction",
                "co2reduction": "tooltips.energy.venue-savings.indicationcostreduction.co2reduction",
                "electricitysavings-percentage": "tooltips.energy.venue-savings.electricitysavings-percentage",
                "electricitysavings-kwh": "tooltips.energy.venue-savings.electricitysavings-kwh",
                "electricityconsumption": "tooltips.energy.venue-savings.electricityconsumption",
                "gassavings-percentage": "tooltips.energy.venue-savings.gassavings-percentage",
                "gassavings": "tooltips.energy.venue-savings.gassavings",
                "gasconsumption": "tooltips.energy.venue-savings.gasconsumption",
                "heatsavings-percentage": "tooltips.energy.venue-savings.heatsavings-percentage",
                "heatsavings": "tooltips.energy.venue-savings.heatsavings",
                "heatconsumption": "tooltips.energy.venue-savings.heatconsumption"
            },
            "temperaturegraph": {
                "titletooltip": "tooltips.energy.temperaturegraph.titletooltip"
            }
        }
    },
    "generalteaser": {
        "title": "generalteaser.title",
        "maintext": "generalteaser.maintext",
        "learnmore": "generalteaser.learnmore"
    }
};

export default translations;
