const links = {
    home: "/",
    map: "/map",
    cleaning: {
        _prefix: "/cleaning",
        pollution: "/cleaning/pollution"
    },
    indoorclimate: {
        _prefix: "/indoorclimate",
        general: "/indoorclimate/general"
    },
    realestateutilization: {
        _prefix: "/realestateutilization",
        insights: "/realestateutilization/insights",
        motion: "/realestateutilization/motion",
        heatmap: "/realestateutilization/heatmap",
        sanitary: "/realestateutilization/sanitary",
        noshow: "/realestateutilization/noshow"
    },
    energymanagement: {
        _prefix: "/energymanagement",
        portfolio: "/energymanagement/portfolio",
        overview: "/energymanagement/overview",
        savings: "/energymanagement/savings",
        reports: {
            _prefix: "/energymanagement/reports",
            baseline: "/energymanagement/reports/baseline",
            weekoverview: "/energymanagement/reports/weekoverview",
            holidays: "/energymanagement/reports/holidays",
            temperature: "/energymanagement/reports/temperature"
        }
    },
    documents: "/documents",
    notifications: {
        _prefix: "/notifications",
        realestateutilization: "/notifications/realestateutilization",
        indoorclimate: "/notifications/indoorclimate",
    },
    settings: {
        _prefix: "/settings",
        realestateutilization: {
            _prefix: "/settings/realestateutilization",
            availability: "/settings/realestateutilization/availability",
            workspacereservations: "/settings/realestateutilization/workspacereservations",
            roomreservations: "/settings/realestateutilization/roomreservations",
            tags: "/settings/realestateutilization/tags",
            paperbins: "/settings/realestateutilization/paperbins",
            toilets: "/settings/realestateutilization/toilets",
        },
        indoorclimate: {
            _prefix: "/settings/indoorclimate",
            notifications: "/settings/indoorclimate/notifications"
        }
    },
    external: {
        beyondeyes: {
            realestateutilization: "https://www.beyondeyes.com/diensten/vastgoedgebruik",
            cleaning: "https://www.beyondeyes.com/diensten/schoonmaak",
            energymanagement: "https://www.beyondeyes.com/diensten/energiemanagement",
            indoorclimate: "https://www.beyondeyes.com/diensten/binnenklimaat"
        }
    }
};

export default links;
