import * as React from "react";
import { useEffect, useState } from "react";
import { NotificationManager } from 'react-notifications';
import Select, { OnChangeValue } from 'react-select';
import { Link } from "react-router-dom";
import DataTable, { TableColumn } from 'react-data-table-component';

import { withTelemetry } from "@/services/telemetryService";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";
import SelectBoxUtils from "@/utils/selectBoxUtils";
import NoShowSettingsService from '@/services/noShowSettingsService';
import IReactSelectValue from "@/interfaces/IReactSelectValue";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import FullPageLoader from "@/components/loaders/fullPageLoader";
import GeneralTeaser from "@/components/teaser/generalTeaser";
import links from "@/utils/links";
import VenueProvider from "@/providers/venueProvider";
import RoomSettings from "./roomSettings";
import { deleteRoomPhoto, upsertRoomPhoto, upsertRoomsSettingsForVenueAsync, upsertAutomaticCancellationSettingAsync, getRoomsSettingsForVenueAsync, getVenueRoomPhotos } from "services/roomSettingsService";
import { Reservation_EnabledCheck, Reservation_DisabledCheck, flipchart, beamer, whiteboard, videoConferencing, tv, MagnifyingGlass } from "@/images";
import IRoomSettingsForRender from "interfaces/IRoomSettingsForRender";
import IRoomPhotoForCreationDto from "interfaces/IRoomPhotoForCreationDto";
import CoreSpaceService from "services/coreSpaceService";
import CoreSpaceIncludes from "enums/coreSpaceIncludes";
import RealEstateUtilizationSettingsPage from "../../realEstateUtilizationSettingsPage";
import IAutomaticCancellationForRoomsSetting from "interfaces/IAutomaticCancellationForRoomsSetting";
import './realEstateUtilizationRoomsSettingsPage.scss';
import PageUtils from "@/utils/pageUtils";
import ExternalConnectionType from "@/enums/externalConnectionType";
import INoShowSettings from "@/interfaces/INoShowSettings";
import DashboardService from "@/services/dashboardService";


const RealEstateUtilizationRoomsSettingsPage: React.FC<{}> = () => {

    const [noShowSettingsService] = useState<NoShowSettingsService>(new NoShowSettingsService());
    const [coreService] = useState<CoreSpaceService>(new CoreSpaceService());
    const [dashboardService] = useState<DashboardService>(new DashboardService());

    const [initialRoomSettings, setInitialRoomSettings] = useState<IRoomSettingsForRender[]>([]);
    const [roomsSettings, setRoomsSettings] = useState<IRoomSettingsForRender[]>([]);
    const [noShowSettings, setNoShowSettings] = useState<INoShowSettings>({ noShowAfter: 30 });
    const [autoCancellationSettings, setAutoCancellationSettings] = useState<IAutomaticCancellationForRoomsSetting>({ isAutomaticCancellationForRoomsEnabled: false, automaticCancellationAfterInMinutes: 30 });

    const [hasAtLeastOneValidSubscription, setHasAtLeastOneValidSubscription] = useState<boolean>(false);
    const [hasNoShowSubscription, setHasNoShowSubscription] = useState<boolean>(false);
    const [hasAutomaticRoomCancellationSubscription, setHasAutomaticRoomCancellationSubscription] = useState<boolean>(false);
    const [hasRoomSettingsSubscription, setHasRoomSettingsSubscription] = useState<boolean>(false);

    const [floorFilter, setFloorFilter] = useState<string>("");
    const [nameFilter, setNameFilter] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [enableSaveButton, setEnableSaveButton] = useState<boolean>(false);

    useEffect(() => {
        const init = async (): Promise<void> => { await initializeVenueAsync(); }

        init();

        AppEventHub.on(AppEvents.BuildingSelected, initializeVenueAsync);

        return (): void => { AppEventHub.off(AppEvents.BuildingSelected, initializeVenueAsync) };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function initializeVenueAsync(): Promise<void> {

        const subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();

        if (!subscriptionValidationService) { return; }

        setIsLoading(true);

        const venue = await coreService.getVenueById(VenueProvider.getActiveVenue()!.id, [CoreSpaceIncludes.Properties], true);

        const autoCancellationSettings: IAutomaticCancellationForRoomsSetting = {
            isAutomaticCancellationForRoomsEnabled: venue!.isAutomaticCancellationForRoomsEnabled ?? false,
            automaticCancellationAfterInMinutes: venue.automaticCancellationAfter
        };

        const hasAutomaticRoomCancellationSubscription = subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["AutomaticRoomCancellation"]);
        const hasNoShowSubscription = subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["NoShowRooms"]);
        const hasRoomSettingsSubscription = subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["BEFMISRooms"]);

        const noShowSettings = await noShowSettingsService.getNoShowSettings(true);

        if (hasRoomSettingsSubscription) {
            try {
                const [roomSettings, roomPhotos] = await Promise.all([
                    getRoomsSettingsForVenueAsync(venue!.id, true),
                    getVenueRoomPhotos()
                ]);

                roomPhotos.forEach(photo => {
                    const room = roomSettings.find(room => room.spaceId === photo.spaceId);
                    if (room !== undefined) {
                        room.uploadedRoomPhoto = photo;
                    }
                });

                setRoomsSettings(roomSettings);
                setInitialRoomSettings(roomSettings);
            } catch (e) { return; } // Forbidden
        }

        if (!isNaN(noShowSettings.noShowAfter)) {
            setNoShowSettings(noShowSettings);
        }

        setIsLoading(false);
        setHasAtLeastOneValidSubscription(hasNoShowSubscription || hasAutomaticRoomCancellationSubscription || hasRoomSettingsSubscription);
        setHasNoShowSubscription(hasNoShowSubscription);
        setHasAutomaticRoomCancellationSubscription(hasAutomaticRoomCancellationSubscription);
        setHasRoomSettingsSubscription(hasRoomSettingsSubscription);
        setAutoCancellationSettings(autoCancellationSettings);
        setEnableSaveButton(false);
    }

    function getColumns(): TableColumn<IRoomSettingsForRender>[] {

        const columns: TableColumn<IRoomSettingsForRender>[] = [
            {
                id: 'floorLevel',
                name: LanguageProvider.getTranslation('pages.settings.roomreservations.floorlevel'),
                selector: (row): string => row.floorLevel
            },
            {
                id: 'name',
                name: LanguageProvider.getTranslation('pages.settings.roomreservations.roomname'),
                selector: (row): string => row.name
            },
            {
                id: 'capacity',
                name: LanguageProvider.getTranslation('pages.settings.roomreservations.capacity'),
                selector: (row): number => row.capacity
            },
            {
                id: 'facilities',
                name: LanguageProvider.getTranslation('pages.settings.roomreservations.facilities'),
                selector: (row): any => {
                    return renderColumnFacilities(row);
                }
            },
            {
                id: 'isReservable',
                name: LanguageProvider.getTranslation('pages.settings.roomreservations.reservationenabled'),
                selector: (row): any => {
                    return renderColumnReservable(row);
                }
            }
        ];

        return columns;
    }

    function getSelectOption(timeInMinutes: number): IReactSelectValue {
        return { label: `${timeInMinutes} ${LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.labels.minutes)}`, value: `${timeInMinutes}` };
    }

    function getNoShowAfterLabels(): IReactSelectValue[] {
        return [
            getSelectOption(10),
            getSelectOption(15),
            getSelectOption(30)
        ];
    }

    function onNoShowAfterChange(selectedItem: OnChangeValue<IReactSelectValue, false>): void {
        const item = selectedItem as IReactSelectValue;
        const settings = noShowSettings;
        settings.noShowAfter = parseInt(item.value, 10);

        setNoShowSettings({ ...settings });
        setEnableSaveButton(true);
    }

    function getCancellationAfterLabels(): IReactSelectValue[] {
        return [
            getSelectOption(10),
            getSelectOption(15),
            getSelectOption(30),
            getSelectOption(45)
        ];
    }

    function onCancellationAfterChange(selectedItem: OnChangeValue<IReactSelectValue, false>): void {
        const item = selectedItem as IReactSelectValue;
        const settings = autoCancellationSettings;
        settings.automaticCancellationAfterInMinutes = parseInt(item.value, 10);

        setAutoCancellationSettings({ ...settings });
        setEnableSaveButton(true);
    }

    function handleRoomSettingsChanged(room: IRoomSettingsForRender): void {
        const roomIndex = roomsSettings.findIndex(r => r.spaceId === room.spaceId);

        const updatedRoomsSettings = [...roomsSettings];
        updatedRoomsSettings[roomIndex] = room;

        setRoomsSettings(updatedRoomsSettings);
        setEnableSaveButton(true);
    }

    async function handleRoomPhotoUpload(roomPhotoForUpload: IRoomPhotoForCreationDto): Promise<any> {
        const venue = VenueProvider.getActiveVenue();
        roomPhotoForUpload.venueId = venue?.id;
        try {
            const response = await upsertRoomPhoto(roomPhotoForUpload);
            if (response.status !== 201) {
                NotificationManager.error(LanguageProvider.getTranslation(translations.pages.settings.roomreservations.photouploaderror));
            } else {
                const newRoomSettings = [...roomsSettings];
                const roomIndex = newRoomSettings.findIndex(room => room.spaceId === roomPhotoForUpload.spaceId);
                const newImageUrl = await response.json().then(imageUrl => { return imageUrl; });
                const uniqueImageDifferentiator = Date.now();
                const combinedImageUrl = `${newImageUrl}?${uniqueImageDifferentiator}`;
                newRoomSettings[roomIndex].uploadedRoomPhoto = { spaceId: roomPhotoForUpload.spaceId, fileLocation: combinedImageUrl };

                setRoomsSettings(newRoomSettings);
                NotificationManager.success(LanguageProvider.getTranslation(translations.pages.settings.roomreservations.photosaved));
                return newImageUrl;
            }
        } catch (error) {
        }
    }

    async function handleRoomPhotoDelete(spaceId: string): Promise<void> {
        try {
            const response = await deleteRoomPhoto(spaceId);
            if (response.status !== 204) {
                NotificationManager.error(LanguageProvider.getTranslation("pages.settings.roomreservations.photodeletederror"));

            } else {
                const newRoomSettings = [...roomsSettings];
                const roomIndex = newRoomSettings.findIndex(room => room.spaceId === spaceId);
                newRoomSettings[roomIndex].uploadedRoomPhoto = undefined;
                NotificationManager.success(LanguageProvider.getTranslation(`pages.settings.roomreservations.photodeleted`));

                setRoomsSettings(newRoomSettings);
            }
        } catch (error) { }
    }

    function handleAutoCancelToggle(): void {
        const autoCancellationSettingsLocal = autoCancellationSettings;
        autoCancellationSettingsLocal.isAutomaticCancellationForRoomsEnabled = !autoCancellationSettingsLocal.isAutomaticCancellationForRoomsEnabled;
        setAutoCancellationSettings({ ...autoCancellationSettingsLocal });
        setEnableSaveButton(true);
    }

    function checkCapacityInput(input: number): boolean {
        const onlyNumberRegex = /^[0-9\b]+$/;
        return input !== undefined
            && input.toString() !== ""
            && onlyNumberRegex.test(input.toString());
    };

    function isRoomSettingsFormValid(): boolean {
        let isFormValid = true;
        for (const roomSettings of roomsSettings) {
            if (!checkCapacityInput(roomSettings.capacity)) {
                NotificationManager.error(LanguageProvider.getTranslation(translations.pages.settings.roomreservations.invalidcapacity));
                isFormValid = false;
                break;
            }
        }

        return isFormValid;
    }

    async function handleSubmit(): Promise<void> {
        setEnableSaveButton(false);
        setIsSaving(true);

        const venue = VenueProvider.getActiveVenue();

        let roomSettingsResultStatus;

        if (hasRoomSettingsSubscription) {
            const isFormValid = isRoomSettingsFormValid();
            if (!isFormValid) {
                setIsSaving(false);
                return;
            }

            const roomSettingsResult = await upsertRoomsSettingsForVenueAsync(venue!.id, roomsSettings);
            roomSettingsResultStatus = roomSettingsResult.status;
        }

        const noShowResult = await noShowSettingsService.upsertNoShowSettings(noShowSettings);
        const automaticCancellationResult = await upsertAutomaticCancellationSettingAsync(venue!.id, autoCancellationSettings);
        const successfullyUpdated = noShowResult.status === 204 && (roomSettingsResultStatus === 200 || roomSettingsResultStatus === undefined) && automaticCancellationResult.status === 200;

        if (successfullyUpdated) {
            NotificationManager.success(LanguageProvider.getTranslation(translations.pages.settings.roomreservations.saved));
            if (hasRoomSettingsSubscription) {
                await getRoomsSettingsForVenueAsync(venue!.id, true);
            }
        }
        else {
            NotificationManager.error(LanguageProvider.getTranslation(translations.pages.settings.roomreservations.erroronsave));
        }

        setEnableSaveButton(!successfullyUpdated);
        setIsSaving(false);
    }

    function getDayAbbreviations(cellProps: IRoomSettingsForRender): string {
        const dayAbbreviations = LanguageProvider.getTranslation(translations.pages.settings.roomreservations.dayabbreviations).split(' ');
        const openDaysStringBits = cellProps.accessibleDays.split('');
        const openDaysBools = openDaysStringBits.map(dayBit => !!parseInt(dayBit));

        const resultingString = dayAbbreviations.filter((day, index) => openDaysBools[index]).join(" - ");

        return resultingString;
    }

    function renderColumnReservable(row: IRoomSettingsForRender): JSX.Element {
        if (row.isReservable) {
            return <span><img className="mr-2" src={Reservation_EnabledCheck} />{getDayAbbreviations(row)}</span>;
        } else {
            return <span><img src={Reservation_DisabledCheck} /></span>;
        }
    }

    function renderColumnFacilities(row: IRoomSettingsForRender): JSX.Element {
        let facilityArray = row.facilities;
        facilityArray = facilityArray?.filter((word: string | any[]) => word.length > 0).map((word: string) => word.toLowerCase());
        const iconClasses = 'mr-2 facility-column-icon';
        return (
            <>
                {facilityArray?.includes('beamer') && <img className={iconClasses} src={beamer} />}
                {facilityArray?.includes('tv') && <img className={iconClasses} src={tv} />}
                {facilityArray?.includes('flipchart') && <img className={iconClasses} src={flipchart} />}
                {facilityArray?.includes('whiteboard') && <img className={iconClasses} src={whiteboard} />}
                {facilityArray?.includes('videoconferencing') && <img className={iconClasses} src={videoConferencing} />}
            </>
        );
    }

    function getFilteredRooms(filterId: string, filterValue: string, currentList: IRoomSettingsForRender[]): IRoomSettingsForRender[] {
        const filteredRoomSettings: IRoomSettingsForRender[] = [];
        for (const roomSetting of currentList) {
            if (filterId === "name"
                && roomSetting.name.toLowerCase().includes(filterValue.toLowerCase())
                && filteredRoomSettings.findIndex(i => i.spaceId === roomSetting.spaceId) === -1) {
                filteredRoomSettings.push({ ...roomSetting });
            }
            if (filterId === "floorLevel"
                && roomSetting.floorLevel.toString().toLowerCase().includes(filterValue.toLowerCase())
                && filteredRoomSettings.findIndex(i => i.spaceId === roomSetting.spaceId) === -1) {
                filteredRoomSettings.push({ ...roomSetting });
            }
        }

        return filteredRoomSettings;
    }

    function handleFilterChanged(floorLevelFilter: string, nameFilter: string): void {
        let filteredData = initialRoomSettings;

        if (floorLevelFilter) {
            filteredData = getFilteredRooms("floorLevel", floorLevelFilter, filteredData);
        }

        if (nameFilter) {
            filteredData = getFilteredRooms("name", nameFilter, filteredData);
        }

        setRoomsSettings(filteredData);
    }

    function subHeaderComponent(): JSX.Element {

        return (
            <>
                <div className="customfilter-container">
                    <input placeholder={LanguageProvider.getTranslation('pages.settings.roomreservations.floorlevelplaceholder')}
                        onChange={(event): void => { setFloorFilter(event.target.value); handleFilterChanged(event.target.value, nameFilter) }} />
                    <img src={MagnifyingGlass} />
                </div>
                <div className="customfilter-container">
                    <input placeholder={LanguageProvider.getTranslation('pages.settings.roomreservations.roomnameplaceholder')}
                        onChange={(event): void => { setNameFilter(event.target.value); handleFilterChanged(floorFilter, event.target.value) }} />
                    <img src={MagnifyingGlass} />
                </div>
            </>
        )
    }

    function renderNoShowSettings(): JSX.Element {
        return (
            <div className="main-content-body">
                <div>
                    <h2>{LanguageProvider.getTranslation(translations.pages.settings.workspaces.noshowdefinition)}</h2>
                </div>
                <div className="mt-4">
                    <p>{LanguageProvider.getTranslation(translations.pages.settings.roomreservations.noshowdescription.start)}
                        <Link to={links.realestateutilization.noshow}>{LanguageProvider.getTranslation(translations.pages.settings.roomreservations.noshowdescription.link)}</ Link>
                        {LanguageProvider.getTranslation(translations.pages.settings.roomreservations.noshowdescription.end)}</p>
                </div>
                <div className="mt-4">
                    <h3>{LanguageProvider.getTranslation(translations.pages.settings.workspaces.noshowafter)}</h3>
                    <div className="d-flex flex-row">
                        <div className="settings-dropdown mb-5">
                            <Select
                                value={getSelectOption(noShowSettings.noShowAfter)}
                                onChange={onNoShowAfterChange}
                                options={getNoShowAfterLabels()}
                                styles={SelectBoxUtils.getDefaultSelectColoredStyles(40)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderRoomSettings(): JSX.Element {
        return (
            <div className="main-content-body">
                <h2>{LanguageProvider.getTranslation(translations.pages.settings.roomreservations.roomsettingstitle)}</h2>
                <p>{LanguageProvider.getTranslation(translations.pages.settings.roomreservations.settingsdescription)}</p>
                <div className="row" id="room-settings">
                    <div className="col-sm">
                        <DataTable
                            className="besense-grey-border-table table room-settings"
                            progressPending={isLoading}
                            columns={getColumns()}
                            data={roomsSettings}
                            pagination={true}
                            paginationPerPage={5}
                            paginationComponentOptions={PageUtils.getDefaultPaginationOptions()}
                            subHeader
                            subHeaderComponent={subHeaderComponent()}
                            expandableRows
                            expandableRowsComponent={(row: any): JSX.Element => {
                                const roomForSubComponent = roomsSettings.find(i => i.spaceId === row.data.spaceId)!;

                                return <RoomSettings
                                    onRoomSettingChange={(item: IRoomSettingsForRender): void => {
                                        handleRoomSettingsChanged(item);
                                    }}
                                    onRoomPhotoUpload={handleRoomPhotoUpload}
                                    onRoomPhotoDelete={handleRoomPhotoDelete}
                                    room={roomForSubComponent}
                                />;
                            }} />
                    </div>
                </div>
            </div>
        );
    }

    function renderRoomReservationsSummary(): JSX.Element {
        return (
            <>
                <div className="row header-margin-bottom-paragraph">
                    <div className="col-sm">
                        <h1>{LanguageProvider.getTranslation(translations.pages.settings.realestateutilization.roomreservations)}</h1>
                        <p>{LanguageProvider.getTranslation(translations.pages.settings.roomreservations.description)}</p>
                    </div>
                </div>
            </>
        );
    }

    function renderAutomaticCancellationSettings(): JSX.Element {
        return (
            <div className="main-content-body">
                <div>
                    <h2>{LanguageProvider.getTranslation(translations.pages.settings.workspaces.automaticcancellationsettings)}</h2>
                </div>
                <div className="d-flex flex-row align-items-center">
                    <input
                        id="auto-cancel-checkbox"
                        type="checkbox"
                        className="auto-cancel-checkbox"
                        data-cy={"auto-cancel-checkbox"}
                        readOnly={true}
                        checked={autoCancellationSettings.isAutomaticCancellationForRoomsEnabled}
                        onChange={handleAutoCancelToggle} />
                    <span
                        id="auto-cancel-span"
                        className={autoCancellationSettings.isAutomaticCancellationForRoomsEnabled ? 'span-custom-checkbox checked' : 'span-custom-checkbox'} />
                    <label className="auto-cancel-label" htmlFor="autoCancelLabel">
                        {LanguageProvider.getTranslation(translations.pages.settings.roomreservations.automaticcancellationprefix)}
                        {autoCancellationSettings.automaticCancellationAfterInMinutes}
                        {LanguageProvider.getTranslation(translations.pages.settings.roomreservations.automaticcancellationsuffix)}
                    </label>
                </div>
                <div className="mt-4">
                    <h3>{LanguageProvider.getTranslation(translations.pages.settings.roomreservations.automaticcancellationafter)}</h3>
                    <div className="d-flex flex-row">
                        <div className="settings-dropdown mb-5">
                            <Select
                                value={getSelectOption(autoCancellationSettings.automaticCancellationAfterInMinutes)}
                                onChange={onCancellationAfterChange}
                                options={getCancellationAfterLabels()}
                                styles={SelectBoxUtils.getDefaultSelectColoredStyles(40)}
                                isDisabled={!autoCancellationSettings.isAutomaticCancellationForRoomsEnabled} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <RealEstateUtilizationSettingsPage
                displaySaveButton={hasAtLeastOneValidSubscription}
                onSave={handleSubmit}
                isSaving={isSaving}
                enableSaveButton={enableSaveButton}>
                {isLoading && <FullPageLoader loading={isLoading} />}
                {!isLoading &&
                    <>
                        {!hasAtLeastOneValidSubscription && <GeneralTeaser buttonLink={links.external.beyondeyes.realestateutilization} />}
                        {hasAtLeastOneValidSubscription &&
                            <div className="main-content" id="noshow-settings">
                                {renderRoomReservationsSummary()}
                                {hasNoShowSubscription && renderNoShowSettings()}
                                {hasAutomaticRoomCancellationSubscription && renderAutomaticCancellationSettings()}
                                {hasRoomSettingsSubscription && renderRoomSettings()}
                            </div>}
                    </>
                }
            </RealEstateUtilizationSettingsPage>
        </>
    );
}

export default withTelemetry(RealEstateUtilizationRoomsSettingsPage, "RealEstateUtilizationRoomsSettingsPage");