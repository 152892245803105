import colors from "@/styles/_colors.scss";
export default class Colors {
	public static alto_grey: string = colors.alto_grey;
	public static amaranth_red: string = colors.amaranth_red;
	public static aquamarine: string = colors.aquamarine;
	public static barley_white_yellow: string = colors.barley_white_yellow;
	public static be_box_shadow_black: string = colors.be_box_shadow_black;
	public static energymanagement_green: string = colors.energymanagement_green;
	public static black: string = colors.black;
	public static blue_grey: string = colors.blue_grey;
	public static dark_grey: string = colors.dark_grey;
	public static dawn_pink: string = colors.dawn_pink;
	public static delta_grey: string = colors.delta_grey;
	public static light_grey: string = colors.light_grey;
	public static light_purple: string = colors.light_purple;
	public static link_water_blue: string = colors.link_water_blue;
	public static midnight_black: string = colors.midnight_black;
	public static grey: string = colors.grey;
	public static persian_red: string = colors.persian_red;
	public static royal_blue: string = colors.royal_blue;
	public static shakespeare_blue: string = colors.shakespeare_blue;
	public static signal_green: string = colors.signal_green;
	public static signal_orange: string = colors.signal_orange;
	public static signal_red: string = colors.signal_red;
	public static sunglow_yellow: string = colors.sunglow_yellow;
	public static turbo_yellow: string = colors.turbo_yellow;
	public static white: string = colors.white;
	public static wild_sand_gray: string = colors.wild_sand_gray;
	public static wisp_pink: string = colors.wisp_pink;
	public static zest_brown: string = colors.zest_brown;
}
